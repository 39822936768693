import { render, staticRenderFns } from "./KLMY-pay.vue?vue&type=template&id=6f4cf1d4&scoped=true&"
import script from "./KLMY-pay.vue?vue&type=script&lang=js&"
export * from "./KLMY-pay.vue?vue&type=script&lang=js&"
import style0 from "./KLMY-pay.vue?vue&type=style&index=0&id=6f4cf1d4&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f4cf1d4",
  null
  
)

export default component.exports