<template>
  <div class="add-authorization-info" v-loading="loading">
    <el-form ref="form" :model="formData">
      <el-row type="flex" justify="space-between">
        <el-col>
          <el-form-item label="选择企业" prop="xzqy">
            <el-select
              v-model="formData.xzqy"
              placeholder="请选择"
              filterable
              @change="selectedBankUser"
            >
              <el-option
                v-for="(item, i) in bankUsesrList"
                :label="item.name"
                :value="item.id"
                :key="i"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="授权有效期" prop="sqyxq">
            <dict-select type="授权有效期" v-model="formData.sqyxq"> </dict-select>
          </el-form-item>
        </el-col>
        <el-col
          ><el-button type="primary" @click="searchHouses">房产查询</el-button></el-col
        >
      </el-row>
    </el-form>
    <!--    <div class="tip flex-space-between" v-if="showTip">-->
    <!--      <span>请勾选需要授权的不动产信息</span>-->
    <!--      <i class="el-icon-close" @click="showTip = false"></i>-->
    <!--    </div>-->
    <e-table
      ref="myApplicationRef"
      :tableList="tableList"
      :columns="columns"
      :options="options"
      @selectionChangeHandle="selectionChangeHandle"
    ></e-table>
    <div class="btn text-center">
      <el-button @click="back" type="primary">返回</el-button>
      <el-button @click="save" type="primary" :disabled="isDisabled">保存</el-button>
    </div>
  </div>
</template>

<script>
import { localDataUser } from "@/pages/iebdc/utils/local-data";
import { checkCertifyInspect } from "@/pages/iebdc/api/qszmcx-service";
import authorizationSearch from "@/pages/iebdc/api/authorization-search";
import { getCookie } from "@iebdc/utils/cookies";
import { mapGetters } from "vuex";

export default {
  name: "add-authorization-info",
  props: {
    backTabs: Function,
  },
  data() {
    return {
      selectedHouses: [],
      formData: {
        xzqy: "",
        sqyxq: "",
      },
      userInfo: {},
      showTip: true, //是否展示提示信息
      bankUsesrList: [],
      btnsConfig: {
        showResetBtn: true,
        clearBtnText: "重置",
      },
      filterGroup: [
        {
          width: "120px",
          label: "授权方名称",
          type: "select",
          placeholder: "请选择",
          key: "sqfmc",
          value: "",
        },
        {
          type: "dicSelect",
          dicType: "授权状态",
          label: "授权状态",
          placeholder: "请选择",
          key: "sqzt",
          value: "",
        },
        {
          label: "授权起止时间",
          type: "dateRange",
          centerLabel: false,
          options: [
            {
              key: "sqkssj",
              value: "",
              valueformat: "yyyy-MM-dd",
              placeholder: "请选择",
            },
            {
              key: "sqjssj",
              value: "",
              valueformat: "yyyy-MM-dd",
              placeholder: "请选择",
            },
          ],
        },
      ],
      EDialogConfig: {
        visible: false, //默认不显示更多
        maxLength: 5,
      },
      tableList: [],
      columns: [
        {
          prop: "bdcqzh",
          label: "不动产权证号",
        },
        {
          prop: "qlrmc",
          label: "权利人名称",
        },
        {
          prop: "qlrzjh",
          label: "证件号",
        },
        {
          prop: "djsj",
          label: "登记时间",
        },
        {
          prop: "fwzt",
          label: "房屋状态",
          formatter: (scope) => {
            // 拼接是否查封，是否抵押
            let sfcf = "";
            let sfdy = "";
            if (scope.sfcf === "0" || !scope.sfcf) {
              sfcf = "未查封";
            } else {
              sfcf = "已查封";
            }
            if (scope.sfdy === "0" || !scope.sfdy) {
              sfdy = "未抵押";
            } else {
              sfdy = "已抵押";
            }
            return `<span>${sfcf}${sfdy}</span>`;
          },
        },
        {
          prop: "djjg",
          label: "登记机构",
        },
      ],
      options: {
        // 每页数据数
        pageSize: 10,
        // 当前页码
        currentPage: 1,
        loading: true,
        mutiSelect: true,
        hasIndex: true,
        height: "320px",
        maxHeight: "556px",
        hasPagination: false,
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["selectedOrganizationsTitle"]),
    // 保存按钮状态
    isDisabled: function () {
      return !this.formData.xzqy || !this.formData.sqyxq;
    },
  },
  methods: {
    back() {
      this.$emit("backTabs");
    },
    // 获取银行企业列表
    getBankUser() {
      authorizationSearch.authorizeBankUserGet().then((res) => {
        if (res.success && res.data) {
          this.bankUsesrList = [...res.data];
        }
      });
    },
    // 被授权的银行企业
    selectedBankUser(bankUser) {
      for (let i = 0; i < this.bankUsesrList.length; i++) {
        if (this.bankUsesrList[i].id === bankUser) {
          this.bankUser = this.bankUsesrList[i];
          break;
        }
      }
    },
    // 查房产
    searchHouses() {
      this.loading = true;
      const params = {
        loginName: this.userInfo.loginName,
        qlrmc: this.userInfo.name,
        zjh: "",
        zjzl: "",
        qydm: "460000",
      };
      // 开发商查房参数vs个人查房参数
      if (this.userInfo.userCategory === "NORMAL") {
        params.zjzl = this.userInfo.zjzl;
        params.zjh = this.userInfo.zjh;
      } else {
        params.zjzl = this.userInfo.qyzjzl;
        params.zjh = this.userInfo.qyzjh;
        // params.qlrmc = '12';
        // params.zjzl = '99';
        // params.zjh = '1';
      }
      params.qydm = getCookie("selectedAreaCode"); // 区域代码
      params.qqjgmc = this.selectedOrganizationsTitle;
      checkCertifyInspect(params)
        .then((res) => {
          if (res.success && res.data) {
            this.loading = false;
            this.$refs.myApplicationRef.setTableList(res.data.bdcxxList);
            this.$refs.myApplicationRef.setTotal(res.data.recordCount);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 选中的房产
    selectionChangeHandle(val) {
      this.selectedHouses = [...val];
    },
    // 新增授权
    save() {
      const params = {
        authorizeId: this.bankUser.id,
        authorizeName: this.bankUser.name,
        authorizeUserName: "",
        conditionList: this.selectedHouses,
        ctime: "",
        endTime: "",
        loginName: this.userInfo.name,
        name: this.userInfo.name,
        ownerId: this.userInfo.id,
        status: "",
        userCategoryEnum: this.userInfo.userCategory,
        utime: "",
        validDay: parseInt(this.formData.sqyxq),
        zjh:
          this.userInfo.userCategory === "NORMAL"
            ? this.userInfo.zjh
            : this.userInfo.qyzjh,
      };
      authorizationSearch.authorizeAdd(params).then((res) => {
        if (res.success) {
          this.$message.success("授权成功！");
          this.back();
        }
      });
    },
  },
  mounted() {
    this.userInfo = localDataUser.get();
    this.getBankUser();
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/common-variables";
@import "src/styles/public";

.add-authorization-info {
  .tip {
    margin: 20px 0;
    padding: 15px 20px 15px 20px;
    background-color: #fff3dd;
    color: #e6a23c;
    font-size: 13px;
    border-radius: 8px;
  }
  .btn {
    width: 100%;
    margin: $spacing-large;
  }
}
/deep/ .el-form {
  .el-form-item {
    .el-select {
      width: auto;
    }
  }
}
</style>
