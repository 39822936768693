<template>
  <div>
    <div class="operate" v-show="!showAddProCurator">
      <el-button type="primary" @click="addProCurator">添加经办人</el-button>
    </div>
    <div>
      <el-form v-show="showAddProCurator" label-width="130px" :model="formData" ref="form">
        <el-row>
          <el-col class="height20" :span="6"> </el-col>
          <el-col :span="12">
            <el-form-item label="经办人账号" prop="loginName" :rules="[rules.required]">
              <el-input v-model="formData.loginName" :disabled="disabled || zhdisabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!disabled">
          <el-col :span="6" class="height20"> </el-col>
          <el-col :span="12">
            <el-form-item label="经办人账号密码" prop="oldpassword" :rules="passwordrule">
              <el-input :type="eyeStatus[0] ? 'text' : 'password'" v-model="formData.oldpassword" :disabled="disabled">
                <i
                  slot="suffix"
                  @click="eyeChange(0)"
                  :class="eyeStatus[0] ? 'iconicon-test' : 'iconguanbi-yanjing'"
                  class="iconfont"
                ></i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="height20"> </el-col>
          <el-col :span="12">
            <el-form-item label="经办人姓名" prop="agentName" :rules="[rules.required]">
              <el-input v-model="formData.agentName" :disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="height20"> </el-col>
          <el-col :span="12">
            <el-form-item label="经办人身份证号" prop="agentCertNo" :rules="[rules.required, rules.idcard]">
              <el-input :disabled="disabled" v-model="formData.agentCertNo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="height20"> </el-col>
          <el-col :span="12">
            <el-form-item label="经办人手机号" prop="agentMobile" :rules="[rules.required, rules.phone]">
              <el-input :disabled="disabled" v-model="formData.agentMobile"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="height20"> </el-col>
          <el-col :span="16">
            <el-form-item class="dataitem" label="账号有效期" prop="agentEffDate" :rules="[rules.required]">
              <el-date-picker
                v-model="formData.agentEffDate"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyyMMdd"
                placeholder="有效时间起"
                :disabled="disabled"
                :picker-options="startTime(formData.agentExpDate)"
              ></el-date-picker
              >&nbsp;
            </el-form-item>
            <el-form-item class="dataitem-z" label="" prop="agentExpDate" :rules="[rules.required]">
              <el-date-picker
                v-model="formData.agentExpDate"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyyMMdd"
                placeholder="有效时间止"
                :disabled="disabled"
                :picker-options="endTime(formData.agentEffDate)"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6" class="height20"> </el-col>
          <el-col :span="12">
            <el-form-item label="绑定角色" prop="roleName" :rules="[rules.required]">
              <el-radio-group v-model="formData.roleName">
                <el-radio :disabled="disabled" :label="'iebdc:dlr:query'">查询角色</el-radio>
                <el-radio :disabled="disabled" :label="'iebdc:dlr:handle'">办理角色</el-radio>
                <el-radio :disabled="disabled" :label="'iebdc:dlr:all'">查询办理角色</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="text-center">
          <el-button type="primary" @click="submit" v-if="!disabled">
            确定
          </el-button>
          <el-button type="primary" @click="showAddProCurator = false">
            返回
          </el-button>
        </el-form-item>
      </el-form>
      <e-table
        v-show="!showAddProCurator"
        :columns="columns"
        :options="options"
        :operates="operates"
        :tableList="tableData"
        height="200"
      ></e-table>
    </div>
  </div>
</template>

<script>
import { localDataUser } from '@/pages/iebdc/utils/local-data';
import { crypto } from 'iebdc/utils/crypto.js';
import { addAgent, getAgentsByUserId, updateAgent, deleteAgent, getDigitalAgentRole } from 'iebdc/api/my-operator';
export default {
  name: 'my-operator',
  props: {
    userId: {
      type: String
    }
  },
  data() {
    return {
      eyeStatus: [false],
      rules: this.rules,
      disabled: false,
      zhdisabled: false,
      formData: {
        userId: this.userId,
        roleName: ''
      },
      agentAccId: '',
      tableData: [],
      showAddProCurator: false,
      passwordrule: [], //密码规则
      columns: [
        {
          label: '证件号',
          prop: 'agentCertNo'
        },
        {
          label: '经办人名称',
          prop: 'agentName'
        },
        {
          label: '联系电话',
          prop: 'agentMobile'
        },
        {
          label: '有效期至',
          prop: 'agentExpDateFormat'
        },
        {
          label: '账户状态',
          prop: 'statusName',
          formatter: (row, column) => {
            if (row[column.prop] === '正常') {
              return `<span style="color:#67c23a">${row.statusName}</span>`;
            }
            if (row[column.prop] === '失效') {
              return `<span style="color:#f56c6c">${row.statusName}</span>`;
            }
            if (row[column.prop] === '过期') {
              return `<span style="color: #909399">${row.statusName}</span>`;
            }
          }
        }
      ],
      options: {
        hasIndex: true,
        hasPagination: false,
        // 每页数据数
        // pageSize: 10,
        // // 当前页码
        // currentPage: 1,
        // loading: true,
        // mutiSelect: false,
        // hasIndex: false,
        // marginTop: '10px',
        maxHeight: '5000px'
        // showTip: false,
      },
      operates: {
        list: [
          {
            show: true,
            spanType: 'link',
            label: '查看',
            color: 'primary',
            showCallback: () => true,
            method: this.viewProCurator
          },
          {
            show: true,
            spanType: 'link',
            label: '编辑',
            color: 'warning',
            showCallback: (row) => {
              return Number(row.state) !== 2;
            },
            method: this.editProCurator
          },
          {
            show: true,
            spanType: 'link',
            label: '删除',
            type: 'danger',
            color: 'danger',
            showCallback: (row) => {
              return Number(row.state) !== 2;
            },
            method: this.deleteProCurator
          }
        ]
      }
    };
  },
  mounted() {
    this.getProCurator();
  },
  created() {
    this.startTime = function(value) {
      return {
        disabledDate(time) {
          return time.getTime() > new Date(value).getTime();
        }
      };
    };
    this.endTime = function(value) {
      return {
        disabledDate(time) {
          return time.getTime() < new Date(value).getTime();
        }
      };
    };
  },
  methods: {
    eyeChange(eq) {
      let oldArr = this.eyeStatus;
      oldArr.forEach((el, index) => {
        if (eq === index) {
          oldArr[index] = !oldArr[eq];
          this.$set(this.eyeStatus, index, oldArr[index]);
        }
      });
    },
    // 获取代理人列表
    getProCurator() {
      getAgentsByUserId({ tokenSno: localDataUser.get().UNIFIED_AUTHORIZATION }).then((res) => {
        if (res.success && res.data) {
          this.tableData = [];
          res.data.forEach((item) => {
            const newDate = new Date();
            item.agentExpDateFormat = item.agentExpDate.replace(/^(\d{4})(\d{2})(\d{2})$/, '$1-$2-$3');
            const date = new Date(item.agentExpDateFormat);
            if (item.state === 2) {
              item.statusName = '失效';
            } else {
              item.statusName = '过期';
              if (newDate < date) {
                item.statusName = '正常';
              }
              this.tableData.push(item);
            }
          });
        }
      });
    },
    // 添加代理人
    addProCurator() {
      this.formData = {
        tokenSno: localDataUser.get().UNIFIED_AUTHORIZATION,
        agentCertNation: 'CHN',
        agentCertType: '身份证'
      };
      this.passwordrule = [this.rules.password, this.rules.required];
      this.$refs.form.clearValidate();
      this.agentAccId = '';
      this.showAddProCurator = true;
      this.disabled = false;
      this.zhdisabled = false;
    },
    // 验证表单
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = null;
          this.formData.password = this.formData.oldpassword;
          if (this.agentAccId) {
            // 编辑
            res = await this.saveEditProCurator();
          } else {
            // 新增
            res = await this.saveProCurator();
          }
          if (res && res.success) {
            this.$message.success('保存成功');
            this.getProCurator();
            this.$refs.form.resetFields();
            this.showAddProCurator = false;
          }
        }
      });
    },
    // 保存代理人,
    saveProCurator() {
      return new Promise((reslove) => {
        addAgent(this.formData).then((res) => {
          if (res && res.data && res.data.success) {
            reslove(res);
          } else {
            this.$message.error(res.message || res.data.message);
          }
        });
      });
    },
    // 获取表格中所选代理人的信息并打开详情页
    getTableRowData(row) {
      if (!row) return;
      this.formData = row;
      //获取角色
      getDigitalAgentRole({
        tokenSno: localDataUser.get().UNIFIED_AUTHORIZATION,
        agentAccId: row.accId
      }).then((res) => {
        if (res && res.success) {
          this.$set(this.formData, 'roleName', res.data[0].name);
          this.showAddProCurator = true;
        } else {
          this.$message.error('获取角色失败');
        }
      });
    },
    // 查看代理人信息
    viewProCurator(index, row) {
      this.getTableRowData(row);
      this.disabled = true;
    },
    // 打开编辑代理人信息页面
    editProCurator(index, row) {
      this.getTableRowData(row);
      this.disabled = false;
      this.zhdisabled = true;
      row.agentAccId = row.accId;
      this.agentAccId = row.agentAccId;
      this.formData.tokenSno = localDataUser.get().UNIFIED_AUTHORIZATION;
      this.formData.pwdFlag = true;
      this.formData.agentCertNation = 'CHN';
      this.passwordrule = [this.rules.password];
      this.$refs.form.clearValidate();
    },
    // 保存修改后的代理人信息
    saveEditProCurator() {
      return new Promise((reslove) => {
        updateAgent(this.formData).then((res) => {
          reslove(res);
        });
      });
    },
    // 删除代理人
    deleteProCurator(index, row) {
      this.$confirm('确定要删除该代理人吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAgent({ tokenSno: localDataUser.get().UNIFIED_AUTHORIZATION, agentAccId: row.accId, deleteFlag: '1' }).then(
          (res) => {
            if (res && res.success) {
              this.$message.success('删除成功');
              this.getProCurator();
            }
          }
        );
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.operate {
  text-align: right;
  margin-bottom: 10px;
}
.height20 {
  height: 20px;
}
.dataitem {
  display: inline-block;
}
.dataitem-z {
  display: inline-block;
  /deep/ .el-form-item__content {
    margin-left: auto !important;
  }
}
/deep/ .e-table {
  position: relative !important;
  .el-table__body-wrapper {
    height: auto !important;
  }
}
</style>
