import request from 'iebdc/utils/request';
import { REST_SERVICE } from 'iebdc/config';

// 获取楼盘申请列表
export const getPageList = (params) => {
  return request({
    url: REST_SERVICE.applybuild.getPageList,
    method: 'get',
    params
  });
};

// 添加
export const addApply = (params) => {
  return request({
    url: REST_SERVICE.applybuild.addApply,
    method: 'post',
    data: params
  });
};

// 根据id获取详情
export const getApplyById = (params) => {
  return request({
    url: REST_SERVICE.applybuild.getApplyById,
    method: 'get',
    params
  });
};

export const findFileById = (params) => {
  return request({
    url: REST_SERVICE.files.fileInfo,
    method: 'get',
    params
  });
};

// 根据id删除申请
export const delApply = (params) => {
  return request({
    url: REST_SERVICE.applybuild.delApply,
    method: 'post',
    data: params
  });
};

// 审核楼盘申请
export const applyCheck = (data) => {
  request({
    url: REST_SERVICE.applybuild.applyCheck,
    method: 'post',
    data: data
  });
};
