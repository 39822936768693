<template>
  <div class="stateBoxStyle">
    <div class="title">
      <h1>{{ stateBoxData.title }}</h1>
    </div>
    <applicationMain :stateBoxData="tableParams" :dictype="stateBoxData.dictype"></applicationMain>
  </div>
</template>

<script>
import { IEBDC_USER_DATA } from '@iebdc/config/app-data';
import applicationMain from '../../my-application/component/application-main';
import utils from '@/utils/utils';
export default {
  name: 'state-box',
  props: ['stateBoxData'],
  data() {
    return {
      tableParams: {} //表格请求数据
    };
  },
  watch: {
    stateBoxData: {
      handler(val) {
        this.tableParams.stepList = val.data;
      },
      deep: true
    }
  },
  components: {
    applicationMain
  },
  mounted() {
    this.init();
  },
  methods: {
    // 初始化数据
    init() {
      this.tableParams = {
        userId: IEBDC_USER_DATA().id,
        stepList: this.stateBoxData.data
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.stateBoxStyle {
  // display: flex;
  // justify-content: center; /* 水平居中 */
  // align-items: center; /* 垂直居中 */
  // /deep/ {
  //   .el-input,
  //   .el-select,
  //   .el-cascader {
  //     width: 150px !important;
  //   }
  // }
  .title {
    margin-bottom: 10px;
  }

  .filter-item {
    margin-right: 8px;
    margin-bottom: 20px;
  }
  .input-label {
    // width: 60px;
    display: inline-block;
    margin-right: 10px;
    text-align: right;
  }
}
</style>
