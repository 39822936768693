<template>
  <div>
    <div class="title" v-if="notShowDetail">
      <e-search
        style="padding: 8px 0"
        :filterGroup="filterGroup"
        :btnsConfig="btnsConfig"
        @search="search"
        @reset="reset"
      ></e-search>
      <e-table
        ref="myApplicationRef"
        :tableList="tableList"
        :columns="columns"
        :operates="operates"
        :options="options"
        @afterCurrentPageClick="afterCurrentPageClickHandle"
      ></e-table>
    </div>

    <e-dialog :config="openAppointmentInfo">
      <template slot="content">
        <el-form ref="detailData" :model="detailData" label-width="100px" disabled>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="预约编号">
                <el-input v-model="detailData.yybh"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预约事项">
                <el-input v-model="detailData.yysx"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="产权证号">
                <el-input v-model="detailData.yyzh"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="办理网点">
                <el-input v-model="detailData.yybsdt"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="受理地点">
                <el-input v-model="detailData.yybsdtdz"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="网点电话">
                <el-input v-model="detailData.bsdtdh"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="预约时间">
                <div class="block">
                  <el-input v-model="detailData.yysxsj"></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </e-dialog>
    <div class="btn-container">
      <!--      <el-button type="primary" @click="back()">返回</el-button>-->
    </div>
    <e-dialog :config="openMatterInfo">
      <template slot="content">
        <MatterInfo :data="guideInfo" :document-list="documentList" />
      </template>
    </e-dialog>
  </div>
</template>

<script>
import ESearch from '@/components/e-search';
import ETable from '@/components/e-table';
import {
  getAppointmentPageList,
  getAppointmentInfoByCode,
  cancelAppointment,
  getMaterialsInfo,
  getGuideInfo
} from '@/pages/iebdc/api/my-appointment.js';
import { IEBDC_USER_DATA } from '@/pages/iebdc/config/app-data';
import { formatDate } from '@/filters';
import MatterInfo from './MatterInfo';
export default {
  name: 'myAppointment',
  data() {
    return {
      detailData: {
        yybh: '',
        yyzh: '',
        yybsdt: '',
        yybsdtdz: '',
        bsdtdh: '',
        yysx: ''
      },
      //弹窗配置项
      openAppointmentInfo: {
        title: '预约详情',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: false
      },
      openMatterInfo: {
        title: '办事指南',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: false
      },
      notShowDetail: true,
      btnsConfig: {
        clearBtnText: '重置',
        showResetBtn: true,
        showMoreBtn: false
      },
      guideInfo: {}, //指南信息
      documentList: [],
      filterGroup: [
        {
          label: '办理网点',
          key: 'yybsdt',
          type: 'input',
          placeholder: '关键字',
          value: ''
        },
        {
          label: '预约时间',
          key: 'yysj',
          type: 'date',
          value: '',
          valueformat: 'yyyy-MM-dd',
          placeholder: '预约时间'
        }
      ],
      tableList: [],
      columns: [
        {
          width: '140px',
          prop: 'yybh',
          label: '预约编号',
          align: 'center'
        },
        {
          width: '140px',
          prop: 'yyyhmc',
          label: '申请人',
          align: 'center'
        },
        {
          width: '140px',
          prop: 'yysx',
          label: '预约事项',
          align: 'center'
        },
        {
          //需拼串的预约时间
          width: '140px',
          prop: 'yysxsj',
          label: '预约时间',
          align: 'center'
        },
        {
          width: '140px',
          prop: 'yybsdt',
          label: '办理网点',
          align: 'center'
        },
        {
          width: '100px',
          prop: 'successNum',
          label: '预约排号',
          align: 'center'
        },
        {
          width: '100px',
          prop: 'status',
          label: '预约状态',
          align: 'center',
          formatter: (row) => {
            if (row.status === '预约成功') {
              return `<span style="color: #3BB141">${row.status}</span>`;
            } else if (row.status === '已受理') {
              return `<span style="color: #0E90FE">${row.status}</span>`;
            } else if (row.status === '过期') {
              return `<span style="color: #aeb5c2">${row.status}</span>`;
            } else if (row.status === '已取消') {
              return `<span style="color: #DE1037">${row.status}</span>`;
            } else {
              return `<span style="color: #0E90FE">${row.status}</span>`;
            }
          }
        }
      ],
      operates: {
        //表格操作栏
        width: '200px',
        fixed: 'center',
        list: [
          {
            show: true,
            spanType: 'button',
            id: '1',
            label: '查看',
            type: 'text',
            size: 'medium',
            color: 'primary',
            method: (key, row) => {
              this.getCodeDetail(row.yybh);
            },
            showCallback: () => {
              return true;
            }
          },
          {
            show: true,
            disabled: (row) => {
              if (row.sfqx === 1 || row.status === '过期') {
                return true;
              }
            },
            spanType: 'button',
            id: '2',
            label: '取消',
            type: 'text',
            size: 'medium',
            color: 'warning',
            method: (key, row) => {
              this.celChoice(row.yybh);
            },
            showCallback: () => {
              return true;
            }
          },
          {
            show: true,
            spanType: 'button',
            id: '3',
            label: '办事指南',
            type: 'text',
            size: 'medium',
            color: 'success',
            method: (key, row) => {
              this.showMatterInfo(row.yyFlowId);
            },
            showCallback: () => {
              return true;
            }
          }

          // {
          //   show: true,
          //   spanType: 'button',
          //   id: '2',
          //   label: '编辑',
          //   type: 'warning',
          //   method: (key, row) => {
          //     console.log(row);
          //   },
          //   showCallback: (row) => {
          //     if (row.step === 'NETAPPLYING' || row.step === 'NETNOPASS') {
          //       return true;
          //     } else {
          //       return false;
          //     }
          //   }
          // },
          // {
          //   show: true,
          //   spanType: 'button',
          //   id: '3',
          //   label: '再次申请',
          //   type: 'info',
          //   method: (key, row) => {
          //     console.log(row);
          //   },
          //   showCallback: (row) => {
          //     if (row.step !== 'NETAPPLYING' || row.step !== 'NETNOPASS' || row.tep !== 'NETCHECKING') {
          //       return false;
          //     } else {
          //       return true;
          //     }
          //   }
          // },
          // {
          //   show: true,
          //   spanType: 'button',
          //   id: '4',
          //   label: '撤回',
          //   type: 'primary',
          //   method: (key, row) => {
          //     console.log(row);
          //   },
          //   showCallback: (row) => {
          //     if (row.step === 'NETCHECKING') {
          //       return true;
          //     } else {
          //       return false;
          //     }
          //   }
          // },
          // {
          //   show: true,
          //   spanType: 'button',
          //   id: '5',
          //   label: '删除',
          //   type: 'danger',
          //   method: (key, row) => {
          //     console.log(row);
          //   },
          //   showCallback: (row) => {
          //     if (row.step === 'NETAPPLYING' || row.step === 'NETNOPASS') {
          //       return true;
          //     } else {
          //       return false;
          //     }
          //   }
          // },
          // {
          //   show: true,
          //   spanType: 'dropdown',
          //   id: '6',
          //   label: '打印',
          //   type: 'warning',
          //   dropdownList: [],
          //   method: (key, row) => {
          //     console.log(row);
          //     if (row.step !== 'NETAPPLYING') {
          //       this.operates.list[5].dropdownList = this.dropdownList.slice(0, 2);
          //     } else if (!row.dyaId) {
          //       this.operates.list[5].dropdownList = this.remove(this.dropdownList, this.dropdownList[2]);
          //     } else if (row.step !== 'PAYING') {
          //       this.operates.list[5].dropdownList = this.remove(this.dropdownList, this.dropdownList[3]);
          //     }
          //   },
          //   showCallback: (row) => {
          //     if (row.step !== 'NETAPPLYING' || (row.step === 'PAYING' && IEBDC_USER_DATA.institutionalUser.name)) {
          //       return true;
          //     } else {
          //       return false;
          //     }
          //   }
          // }
        ]
      },
      options: {
        // 每页数据数
        pageSize: 5,
        // 当前页码
        currentPage: 1,
        loading: true,
        mutiSelect: false,
        hasIndex: false,
        maxHeight: '5000px'
      }
    };
  },
  components: {
    ESearch,
    ETable,
    MatterInfo
  },
  methods: {
    // 初始化数据
    init() {
      let params = {
        nCurrent: 0,
        nSize: 5,
        userId: IEBDC_USER_DATA().id
      };
      this.query(params);
    },
    query(params) {
      getAppointmentPageList(params).then((res) => {
        if (res.success && res.data) {
          if (res.data.page && res.data.page.length > 0) {
            this.tableList = [...res.data.page];
            this.tableList.map((item) => {
              item.yysxsj = `${formatDate(item.yysj, 'yyyy-MM-dd')}  ${item.sjd}`;
            });
            this.$refs.myApplicationRef.setTableList(this.tableList);
          } else {
            this.tableList = [];
          }
          this.$refs.myApplicationRef.setTotal(res.data.recordCount);
          this.$refs.myApplicationRef.setPageSize(res.data.pageSize);
        } else {
          this.$refs.myApplicationRef.setTableList([]);
          this.$refs.myApplicationRef.setTotal(0);
          this.$message.warning(res.message);
        }
      });
    },
    // 查询
    search(queryParams) {
      let params = {
        nCurrent: 0,
        nSize: 5,
        userId: IEBDC_USER_DATA().id
      };
      let searchParams = Object.assign(params, queryParams);
      this.query(searchParams);
      this.$refs.myApplicationRef.setCurrentPage(1);
    },
    // 清除(重置)
    reset() {
      this.init();
      this.$refs.myApplicationRef.setCurrentPage(1);
    },
    // 翻页
    afterCurrentPageClickHandle(val) {
      let params = {
        nCurrent: val - 1,
        nSize: 5,
        userId: IEBDC_USER_DATA().id
      };
      this.query(params);
    },
    // 删除数组指定元素返回新数组且不改变原数组
    remove(arr, item) {
      let result = [];
      arr.forEach((element) => {
        if (element !== item) {
          result.push(element);
        }
      });
      return result;
    },
    //查看
    getCodeDetail(yybh) {
      // this.notShowDetail = false;
      getAppointmentInfoByCode({ yybh: yybh }).then((res) => {
        if (res.success) {
          this.openAppointmentInfo.visible = true;
          res.data.yysxsj = `${formatDate(res.data.yysj, 'yyyy-MM-dd')}  ${res.data.sjd}`;
          this.detailData = res.data;
        }
      });
    },
    //返回
    back() {
      this.notShowDetail = true;
    },
    //取消选择
    celChoice(yybh) {
      this.$confirm('是否确认取消?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          cancelAppointment({ yybh: yybh }).then((res) => {
            if (res.success) {
              this.$message({
                type: 'success',
                message: '取消成功!'
              });
              this.init();
            }
          });
        })
        .catch(() => {});
    },
    //展示办事指南相关信息
    showMatterInfo(id) {
      // console.log(id, 'subcfgId');
      // this.notShowDetail = false;
      this.openMatterInfo.visible = true;
      const param = {
        subcfgId: id
      };
      getGuideInfo.getGuideInfoFn(param).then((result) => {
        if (result.success) {
          // console.log(result.data, '办事指南');
          this.guideInfo = result.data;
        }
      });
      getMaterialsInfo.getMaterialsInfoFn(param).then((res) => {
        if (res.success) {
          if (res.data && res.success) {
            // console.log(res.data, '材料列表');
            this.documentList = res.data;
            for (const item of this.documentList) {
              if (item.sfbx === true) {
                item.sfbx = '是';
              } else {
                item.sfbx = '否';
              }
            }
          }
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.center {
  width: 80%;
  margin: 10px auto 0;

  .btn-container {
    text-align: center;
  }
}
/deep/ .e-table {
  position: relative !important;
  .el-table__body-wrapper {
    height: auto !important;
  }
}
</style>
