<template>
  <div class="container">
    <!-- 111 -->
    <el-form :model="ysData"
             ref="ruleForm"
             label-width="100px"
             class="demo-ruleForm"
             disabled>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="预审意见">
            <el-input type="textarea"
                      v-model="ysData.ysyj"
                      :autosize="{ minRows: 4, maxRows: 6 }"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11">
          <el-form-item label="操作结果"
                        required>
            <el-input v-model="ysData.ysjg"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11"
                :offset="2">
          <el-form-item label="审核人"
                        required>
            <el-input v-model="ysData.ysry"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11">
          <el-form-item required
                        label="日期">
            <el-date-picker type="date"
                            placeholder="选择日期"
                            v-model="ysData.yssj"
                            style="width: 100%"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="jdcx-center flex">
      <label>进度查询</label>
      <div v-for="(item, index) in jdcxImgList"
           class="jdcx-item flex"
           :key="index">
        <div v-if="index !== 0"
             class="jd-jt">
          <img :src="activeProgressImg(item.isActive)" />
        </div>
        <div :class="item.isActive ? 'label-active jd-img' : 'jd-img'">
          <div class="jd-image">
            <img :src="getActiveUrl(item)" />
          </div>
          <span class="jd-title">{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    ysData: Object
  },
  mounted () {
    if (this.ysData) {
      this.setImgActive(this.ysData.step);
    }
  },
  data () {
    return {
      jdt: require('@/pages/iebdc/assets/images/jdt.png'),
      jdt1: require('@/pages/iebdc/assets/images/jdt1.png'),
      jdcxImgList: [
        {
          name: '网上申请中',
          img: require('@/pages/iebdc/assets/images/sqz.png'),
          imgActive: require('@/pages/iebdc/assets/images/sqz1.png'),
          isActive: false
        },
        {
          name: '网上审核中',
          img: require('@/pages/iebdc/assets/images/wssqz.png'),
          imgActive: require('@/pages/iebdc/assets/images/wssqz1.png'),
          isActive: false
        },
        {
          name: '网上审核通过',
          img: require('@/pages/iebdc/assets/images/shtg.png'),
          imgActive: require('@/pages/iebdc/assets/images/shtg1.png'),
          isActive: false
        },
        {
          name: '窗口受理',
          img: require('@/pages/iebdc/assets/images/sl.png'),
          imgActive: require('@/pages/iebdc/assets/images/sl1.png'),
          isActive: false
        },
        {
          name: '窗口审核中',
          img: require('@/pages/iebdc/assets/images/sh.png'),
          imgActive: require('@/pages/iebdc/assets/images/sh1.png'),
          isActive: false
        },
        {
          name: '登薄',
          img: require('@/pages/iebdc/assets/images/db.png'),
          imgActive: require('@/pages/iebdc/assets/images/db1.png'),
          isActive: false
        },
        {
          name: '缮证',
          img: require('@/pages/iebdc/assets/images/sz.png'),
          imgActive: require('@/pages/iebdc/assets/images/sz1.png'),
          isActive: false
        },
        // {
        //   name: '缴费',
        //   img: require('@/pages/iebdc/assets/images/jf.png'),
        //   imgActive: require('@/pages/iebdc/assets/images/jf1.png'),
        //   isActive: false
        // },
        {
          name: '发证',
          img: require('@/pages/iebdc/assets/images/fz.png'),
          imgActive: require('@/pages/iebdc/assets/images/fz1.png'),
          isActive: false
        },
        {
          name: '归档',
          img: require('@/pages/iebdc/assets/images/sl.png'),
          imgActive: require('@/pages/iebdc/assets/images/sl1.png'),
          isActive: false
        }
        // {
        //   name: '办结',
        //   img: require('@/pages/iebdc/assets/images/bw.png'),
        //   imgActive: require('@/pages/iebdc/assets/images/bw1.png'),
        //   isActive: false
        // }
      ]
    };
  },
  methods: {
    //这里是通过获取来的数据进行调用的
    setImgActive (step) {
      for (let i = 0; i < this.jdcxImgList.length; i++) {
        this.jdcxImgList[i].isActive = false;
      }
      if (step === 'NETAPPLYING') {
        for (let i = 0; i < 1; i++) {
          this.jdcxImgList[i].isActive = true;
        }
      } else if (step === 'NETCHECKING') {
        for (let i = 0; i < 2; i++) {
          this.jdcxImgList[i].isActive = true;
        }
      } else if (step === 'NETPASSED') {
        for (let i = 0; i < 3; i++) {
          this.jdcxImgList[i].isActive = true;
        }
      } else if (step === 'ACCEPTANCE') {
        for (let i = 0; i < 4; i++) {
          this.jdcxImgList[i].isActive = true;
        }
      } else if (step === 'CHECKING') {
        for (let i = 0; i < 5; i++) {
          this.jdcxImgList[i].isActive = true;
        }
      } else if (step === 'REGISTERING') {
        for (let i = 0; i < 6; i++) {
          this.jdcxImgList[i].isActive = true;
        }
      } else if (step === 'CERTIFICATING') {
        for (let i = 0; i < 7; i++) {
          this.jdcxImgList[i].isActive = true;
        }
      }
      // else if (step === 'PAYING') {
      //   for (let i = 0; i < 5; i++) {
      //     this.jdcxImgList[i].isActive = true;
      //   }
      // }
      else if (step === 'AWARD') {
        for (let i = 0; i < 8; i++) {
          this.jdcxImgList[i].isActive = true;
        }
      }
      // else if (step === 'COMPLETE') {
      //   for (let i = 0; i < 7; i++) {
      //     this.jdcxImgList[i].isActive = true;
      //   }
      // }
      else if (step === 'GD') {
        for (let i = 0; i < 9; i++) {
          this.jdcxImgList[i].isActive = true;
        }
      }
    },
    getActiveUrl ({ img, imgActive, isActive }) {
      return isActive ? imgActive : img;
    },
    activeProgressImg (isActive) {
      return isActive ? this.jdt1 : this.jdt;
    }
  }
};
</script>

<style lang="scss" scoped>
.container {
  .jdcx-center {
    margin-top: 20px;

    label {
      font-size: 16px;
      float: left;
      line-height: 80px;
      margin-right: 5px;
      font-weight: 700;
    }

    .jdcx-item {
      /*width: 130px;*/
      height: 60px;

      .jd-jt {
        width: 29px;
        line-height: 72px;
        text-align: center;
      }

      .jd-img {
        width: 72px;
        height: 72px;
        font-size: 12px;
        background-color: #f3f3f3;
        border-radius: 5px;
        text-align: center;
        img {
          width: 50px;
          height: 50px;
          // background-color: #f3f3f3;
          // margin-bottom: 10px;
          // border-radius: 50%;
        }
        div {
          height: 48px;
          margin-bottom: 3px;
        }
      }
    }
    .jdcx-box {
      // display: flex;
      // align-items: center;
      // margin-right: 5px;
      // vertical-align: middle;
    }
  }
  .label-active {
    color: #fff;
    background-color: #1492ff !important;
  }
}
</style>
