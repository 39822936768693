import { mapState } from 'vuex';
import { localDataUser } from '@iebdc/utils/local-data.js';
import applicantSaveService from '@/pages/iebdc/api/apply/applicant-save';
import applicantQlxxService from '@/pages/iebdc/api/apply/applicant-qlxx';
import applyConfig from '@public/applyConfig';
import lodash from 'lodash';
export default {
  data() {
    return {
      config: [], //表单配置
      formModal: {
        children: [],
      }, //申请表对象
      userData: localDataUser.get(),
    };
  },
  computed: {
    // 下一步跳转所需数据
    ...mapState('wysq-data', ['firstFlow']),
  },
  methods: {
    getProcessInfo(ywh) {
      // 获取流程数据
      return new Promise((resolve, reject) => {
        applicantSaveService.getProcessInfo({ wwywh: ywh }).then((res) => {
          if (res.success) {
            console.log('applyConfig=', applyConfig, res.data.children);
            if (res.data.children.length > 0) {
              res.data.children.forEach((element) => {
                if (element.qlxxEx) {
                  element.qlxxEx.sfdbz = false;
                  element.qlxxEx.sffbcz = false;
                  element.qlxxEx.sqzsbs = 0;
                }
              });
            }
            console.log('[mixin 获取流程信息 res]', res);
            console.log('[mixin 获取流程信息 res.data]', res.data);
            console.log(
              '[mixin 获取流程信息 this.getConfigByCode(res.data.netFlowdefCode)]',
              this.getConfigByCode(res.data.netFlowdefCode)
            );
            this.config = this.getConfigByCode(res.data.netFlowdefCode);
            console.log('this.config=', this.config);
            var codelist = ['H999001', 'H999006'];
            // 当起草空表单时，组合流程需添加对应qlxx的字段，否则会出现切换表单时出现被强制清空
            if (codelist.includes(res.data.netFlowdefCode) && res.data.children.length == 2) {
              res.data.children.forEach((item, pindex) => {
                var qlxxObj = this.config[pindex].config.filter((item) => item.tableTitle == '不动产情况');
                if (qlxxObj.length === 1) {
                  let obj = {};
                  qlxxObj[0].formConfig[0].value.forEach((itemChild) => {
                    obj[itemChild.key] = item.qlxxExMhs && item.qlxxExMhs[0] ? item.qlxxExMhs[0][itemChild.key] : '';
                  });
                  item.qlxxExMhs = [obj];
                } else if (qlxxObj.length === 2) {
                  let obj = {};
                  if (this.userData.userType === 'gr') {
                    qlxxObj[1].formConfig[0].value.forEach((itemChild) => {
                      obj[itemChild.key] = item.qlxxExMhs && item.qlxxExMhs[0] ? item.qlxxExMhs[0][itemChild.key] : '';
                    });
                    item.qlxxExMhs = [obj];
                  } else {
                    var qlxxList =
                      qlxxObj[0].dialogFormConfig.length == 1
                        ? [...qlxxObj[0].dialogFormConfig[0].value]
                        : [...qlxxObj[0].dialogFormConfig[0].value, ...qlxxObj[0].dialogFormConfig[1].value];
                    qlxxList.forEach((itemChild) => {
                      obj[itemChild.key] = item.qlxxExMhs && item.qlxxExMhs[0] ? item.qlxxExMhs[0][itemChild.key] : '';
                    });
                    item.qlxxExMhs = [obj];
                  }
                }
              });
            }
            if (!this.config) {
              reject('获取配置失败，请在配置文件中配置流程!!!');
            } else {
              this.disabled = res.data.step === 'VERIFICATION' || res.data.step === 'VERIFYSUCCESS' ? true : false;
              applicantQlxxService.setQlxxEx(res.data);
              this.formModal = res.data || {};
              this.formModal.children = this.formModal.children || [{}];
              this.arrangeForm(this.formModal.children);
              console.log('this.formModal', this.formModal);
              resolve();
            }
          } else {
            reject(res.message);
          }
        });
      });
    },
    arrangeForm(formModal) {
      const form = {
        qlrs: [],
        ywrs: [],
        qlxxEx: {}, //预告信息
        qlxxExMhs: [], //不动产情况
        tzrxxes: [],
      };
      formModal.forEach((formData) => {
        for (let key in form) {
          if (!formData[key]) {
            formData[key] = form[key];
          }
        }
      });
    },
    getConfigByCode(code) {
      const config = applyConfig.find((config) => config.code === code);
      console.log(' + ' + config);
      return lodash.cloneDeep(config && config.data);
    },
  },
  mouted() {
    console.log('applyConfig=', applyConfig);
  },
};
