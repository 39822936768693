<template>
  <div class="content">
    <!-- <common-breadcrumb /> -->
    <div class="table-wrap">
      <!-- 头部 -->
      <e-search
        style="padding: 8px 0"
        :btnsConfig="btnsConfig"
        @search="getList"
        @reset="reset"
        ref="search"
        v-model="tableFilters"
      >
      </e-search>
      <div style="width: 100%; height: 40px">
        <el-button style="float: right" type="primary" @click="addApply">新增申请</el-button>
      </div>
      <e-table
        ref="businessTable"
        :options="options"
        :columns="columns"
        :operates="operates"
        row-key="id"
        @afterCurrentPageClick="afterCurrentPageClickHandle"
      />
    </div>
    <e-dialog :config="openUploadInfo" :btns="openSearchBtns" class="dialog">
      <template slot="content">
        <el-form ref="form" :model="sizeForm" :rules="rules" label-width="120px" size="mini">
          <!-- <el-row>
            <el-col :span="24">
              <el-form-item label="不动产坐落" :rules="[vrules.required]">
                <zl-directives v-model="zlData" :zlConfig="zlConfig"></zl-directives>
              </el-form-item>
            </el-col>
          </el-row> -->
          <el-row>
            <el-col :span="12">
              <el-form-item label="宗地代码" prop="zddm">
                <el-input v-model="sizeForm.zddm" placeholder="请输入19位宗地代码"></el-input>
                <!-- <el-select v-model="sizeForm.zddm" placeholder="请选择">
                  <el-option v-for="item in zddmList" :key="item.zddm" :label="item.zddm" :value="item.zddm"></el-option>
                </el-select> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="坐落" prop="zl">
                <el-input v-model="sizeForm.zl"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="上传落宗单" prop="zl">
                <el-upload
                  class="upload-demo"
                  multiple
                  :action="submitFileUrl"
                  :before-upload="beforeUploadHandle"
                  :show-file-list="showFileList"
                  :file-list="fileList"
                  :disabled="!sizeForm.zddm"
                >
                  <el-button size="small" type="primary" :disabled="!sizeForm.zddm">点击上传</el-button>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </e-dialog>
    <e-dialog :config="openDetailInfo" :btns="openDetailBtns" class="dialog">
      <template slot="content">
        <div class="demo-image__preview">
          <div class="block" v-for="(imgFile, index) in showFiles" :key="index">
            <el-image style="width: 200px; height: 200px" :src="imgFile" :preview-src-list="showFiles"></el-image>
          </div>
        </div>
      </template>
    </e-dialog>
  </div>
</template>

<script>
// import vrules from '@/utils/validate.js';
import { REST_SERVICE } from '@iebdc/config/';
import { IEBDC_USER_DATA } from '@iebdc/config/app-data';
import applicantUploadService from '@/pages/iebdc/api/apply/applicant-upload';
import { getPageList, addApply, findFileById, delApply } from '@/pages/iebdc/api/user-center/apply-building.js';

export default {
  name: 'applyBuilding',
  data() {
    return {
      // vrules: vrules,
      btnsConfig: {
        clearBtnText: '重置',
        showResetBtn: true,
        showMoreBtn: false,
      },
      tableFilters: [
        {
          type: 'input',
          label: '宗地代码',
          placeholder: '请输入宗地代码',
          key: 'zddm',
          value: '', // 接口传参 value 值
        },
        {
          type: 'select',
          options: [
            { value: 'NETAPPLYING', name: '审核中' },
            { value: 'NETPASSED', name: '审核通过' },
            { value: 'NETNOPASS', name: '审核不通过' },
          ],
          label: '审核状态',
          placeholder: '请选择审核状态',
          props: {
            label: 'name',
            value: 'value',
          },
          key: 'status',
          value: '', // 接口传参 value 值
        },
      ],
      params: {
        //表格的查询参数
        nCurrent: 0,
        userId: IEBDC_USER_DATA().id,
      },
      options: {
        // 每页数据数
        pageSize: 10,
        // 当前页码
        currentPage: 0,
        loading: true,
        //选择框
        mutiSelect: false,
        //没有斑马线
        stripe: false,
        maxHeight: '5000px',
        //有序列
        hasIndex: true,
      },
      columns: [
        {
          prop: 'zddm',
          label: '宗地代码',
          width: 200,
        },
        {
          prop: 'zl',
          label: '坐落',
          width: 180,
        },
        {
          prop: 'ctime',
          label: '申请时间',
          type: 'date',
          width: 180,
        },
        {
          prop: 'checkerName',
          label: '审核人',
          width: 100,
        },
        {
          prop: 'checkResultEnum',
          label: '审核结果',
          width: 100,
          formatter: (row) => {
            return this.getShztHtml(row);
          },
        },
        {
          prop: 'step',
          label: '审核状态',
          width: 100,
          formatter: (row) => {
            return this.getStepHtml(row);
          },
        },
        {
          prop: 'checkDate',
          label: '审核时间',
          type: 'date',
          width: 180,
        },
        {
          prop: 'checkReason',
          label: '审核意见',
          width: 200,
        },
      ],
      operates: {
        //表格操作栏
        width: 150,
        fixed: 'right',
        list: [
          {
            id: '1',
            label: '落宗单',
            show: true,
            spanType: 'button',
            type: 'text',
            size: 'medium',
            color: 'primary',
            method: (key, row) => {
              this.checkDetail(row);
            },
            showCallback: () => {
              return true;
            },
          },
          {
            id: '2',
            label: '删除',
            show: true,
            spanType: 'button',
            type: 'text',
            size: 'medium',
            color: 'danger',
            method: (key, row) => {
              this.deleteApply(row);
            },
            showCallback: (row) => {
              if (row.checkerId) {
                return false;
              } else {
                return true;
              }
            },
          },
        ],
      },
      // zlConfig: {
      //   zlKey: 'code',
      //   rules: [],
      //   hideZl: true,
      //   disabled: false
      // },
      // zlData: {
      //   zlProvince: '',
      //   zlCity: '',
      //   zlArea: ''
      // },
      sizeForm: {
        zddm: '',
        zl: '',
      },
      rules: {
        zddm: [
          { required: true, message: '请填写宗地代码' },
          { min: 19, max: 19, message: '长度为19个字符', trigger: 'blur' },
        ],
        zl: [{ required: true, message: '必填', trigger: 'blur' }],
      },
      showFileList: true,
      // 上传文件路径
      submitFileUrl: REST_SERVICE.files.mulFileSave,
      fileList: [],
      //弹窗配置项
      openUploadInfo: {
        title: '新增楼盘申请',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: true,
      },
      openSearchBtns: [
        {
          label: '关闭',
          class: 'public-el-button-default',
          method: () => {
            this.openUploadInfo.visible = false;
          },
          show: true,
        },
        {
          label: '保存',
          type: 'primary',
          class: 'public-el-button',
          method: this.toSave,
          show: true,
        },
      ],
      openDetailInfo: {
        title: '落宗单详情',
        width: '765px',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: true,
      },
      openDetailBtns: [
        {
          label: '关闭',
          class: 'public-el-button-default',
          method: () => {
            this.openDetailInfo.visible = false;
          },
          show: true,
        },
      ],
      systemTime: '',
      filesId: '',
      showFiles: [],
    };
  },
  // computed: {
  //   zddmList() {
  //     let result = [];
  //     let allZddm = IEBDC_USER_DATA().zdxxes;
  //     allZddm.forEach((item) => {
  //       if (item.zddm.indexOf(this.zlData.zlArea) >= 0) {
  //         result.push(item);
  //       }
  //     });
  //     return result;
  //   }
  // },
  // watch: {
  //   // 切换行政区时置空已选的宗地代码
  //   zlData: {
  //     handler() {
  //       this.sizeForm.zddm = '';
  //     },
  //     deep: true,
  //     immediate: true
  //   }
  // },
  methods: {
    // 查询宗地代码
    searchNext() {},
    //获取系统时间
    async getSystemTime() {
      const res = await applicantUploadService.getSystemTime();
      this.systemTime = res.data;
    },
    // 新增
    addApply() {
      this.openUploadInfo.visible = true;
    },
    // 保存新增申请
    toSave() {
      this.$refs.form.validate((valid) => {
        if (this.fileList.length === 0) {
          this.$message.warning('请上传落宗单！');
          return false;
        }
        if (valid) {
          let params = {
            userId: IEBDC_USER_DATA().id,
            zddm: this.sizeForm.zddm,
            zl: this.sizeForm.zl,
            fileIdList: this.filesId,
          };
          addApply(params).then(
            (res) => {
              if (res && res.success) {
                this.$message.success('保存成功');
                this.fileList = [];
                this.$refs.form.resetFields();
                this.openUploadInfo.visible = false;
                this.query();
              } else {
                this.$message.error('保存失败');
              }
            },
            () => {
              this.$message.error('保存失败');
            }
          );
        } else {
          this.$message.error('请完善表单!');
          return false;
        }
      });
    },
    // 落宗单详情
    checkDetail(row) {
      let _this = this;
      _this.showFiles = [];
      let allFileIds = row.fileIdList;
      if (allFileIds && allFileIds.length > 0) {
        let promiseAll = [];
        allFileIds.forEach((item) => {
          promiseAll.push(findFileById({ id: item }));
        });
        Promise.all(promiseAll)
          .then((res) => {
            res.forEach((item) => {
              if (item.success) {
                _this.showFiles.push(item.data.filePathUrl);
              }
            });
            _this.openDetailInfo.visible = true;
          })
          .catch((message) => {
            this.$message.error(message);
          });
      }
    },
    // 删除
    deleteApply(row) {
      this.$confirm('是否删除？', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        delApply({ id: row.id }).then(
          (res) => {
            if (res && res.success) {
              this.$message.success('删除成功');
              this.query();
            } else {
              this.$message.error('删除失败');
            }
          },
          () => {
            this.$message.error('删除失败');
          }
        );
      });
    },
    // 获取兼容对象
    getFileObject() {
      // 兼容处理
      let obj = File;
      let ua = navigator.userAgent;
      if (/msie/i.test(ua) || 'ActiveXObject' in window) {
        obj = Blob;
      } else {
        obj = File;
      }
      return obj;
    },
    fileListHandle(file) {
      let fileSuffix = file.name.substring(file.name.lastIndexOf('.'), file.name.length);
      let fileAttr = {
        name: this.sizeForm.zddm + fileSuffix,
        fname: (this.sizeForm.zddm + Date.now() + fileSuffix).replace(/\s*/g, ''),
        type: file.type,
      };

      let obj = this.getFileObject();
      // 修改上传文件名，重新整理进fileList
      this.fileList.push({
        name: file.name,
        fname: fileAttr.fname,
        file: new obj([file], fileAttr.fname),
        attr: this.singleUpload ? Object.assign(fileAttr, { pageType: file.pageType }) : fileAttr,
      });
    },
    getUploadParams(fileList, clmc) {
      let user = JSON.parse(localStorage.getItem('IEBDC_USER_DATA'));
      let formData = new FormData();
      fileList.forEach(({ file, fname }) => {
        formData.append('file', file, fname);
      });
      //上传的磁盘地址
      formData.append('dir', user.userName + '/' + this.systemTime + '/' + this.sizeForm.zddm + '/' + clmc);
      formData.append('recover', true);
      return formData;
    },
    /**
     * 附件文件保存到服务器端
     * @param fileList
     * @param clmc
     * @returns {Promise<void>}
     */
    async uploadFileChange(fileList, clmc) {
      // 上传到FMS
      let { success, data } = await applicantUploadService.upLoadPicture(this.getUploadParams(fileList, clmc));
      if (success) {
        this.filesId = '';
        data.forEach((item, index) => {
          if (index > 0) {
            this.filesId = this.filesId + ',' + item.info.id;
          } else {
            this.filesId = item.info.id;
          }
        });
      }
    },
    // 上传文件前
    beforeUploadHandle(file) {
      this.fileListHandle(file);
      clearTimeout(this.fileTimeout);
      this.fileTimeout = setTimeout(() => {
        //文件整理成功后上传到服务器
        this.uploadFileChange(this.fileList, '绑定楼盘');
      }, 100);
      return false;
    },
    //改变分页查询
    afterCurrentPageClickHandle(value) {
      this.$refs.businessTable.setCurrentPage(value);
      this.params.nCurrent = value - 1;
      this.query();
    },
    //查询列表
    query() {
      getPageList(this.params).then((res) => {
        if (res.success === true) {
          this.$refs.businessTable.setTableList(res.data.page);
          this.$refs.businessTable.setTotal(res.data.recordCount);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //条件查询
    getList(data) {
      this.params = Object.assign(this.params, data);
      this.query();
    },
    //清空条件
    reset() {
      this.params = {
        //表格的查询参数
        nCurrent: 0,
        userId: IEBDC_USER_DATA().id,
      };
      this.afterCurrentPageClickHandle(1);
    },
    //返回审核状态对应值的html
    getShztHtml(row) {
      let html = '';
      if (row.checkResultEnum) {
        switch (row.checkResultEnum) {
          case 'PAST':
            html = '通过';
            break;
          case 'NO_PAST':
            html = '不通过';
            break;
          default:
            break;
        }
      }
      return html;
    },
    // 返回审核步骤值
    getStepHtml(row) {
      let html = '';
      if (row.step) {
        switch (row.step) {
          case 'NETAPPLYING':
            html = '审核中';
            break;
          case 'NETPASSED':
            html = '审核通过';
            break;
          case 'NETNOPASS':
            html = '审核不通过';
            break;
          default:
            break;
        }
      }
      return html;
    },
  },
  created() {
    this.query();
  },
};
</script>

<style lang="scss" scoped>
@import '../../styles/link.scss';
.content {
  min-height: 500px;
}
.block {
  padding: 10px;
  text-align: center;
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}
/deep/ .el-dialog .el-dialog__header button .el-icon-close {
  color: #909399 !important;
}
/deep/ .e-table {
  position: relative !important;
  .el-table__body-wrapper {
    height: auto !important;
  }
}
</style>
