<template>
  <el-form label-width="120px" :model="formData" ref="form" :rules="rules">
    <el-form-item label="已绑定手机">
      <el-input v-model="user.tel" disabled></el-input>
    </el-form-item>
    <el-form-item label="您的手机号" prop="newPhone">
      <el-input v-model="formData.newPhone"></el-input>
    </el-form-item>
    <el-form-item label="手机验证码" prop="authCode">
      <phoneCode v-model="formData.authCode" :phone="formData.newPhone" :disabled="disabled"></phoneCode>
    </el-form-item>
    <el-form-item class="text-center">
      <el-button type="primary" :loading="loadingBtn" @click="submit">确认修改</el-button>
      <el-button type="primary" @click="close">关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { localDataUser } from '@iebdc/utils/local-data';
import { REST_SERVICE } from 'iebdc/config';
import { fetchFun } from 'iebdc/api/login';
import { updataPhone } from 'iebdc/api/user-center/update-userinfo';
import phoneCode from 'iebdc/components/phone-code';
export default {
  name: 'update-phone',
  props: {
    userInfo: {
      type: Object,
    },
  },
  components: {
    phoneCode,
  },
  data() {
    const validatePhone = async (rule, value, callback) => {
      const re = /^((0\d{2,3}-\d{7,8})|((13[0-9])|(14[5,7,9])|(15[^4])|(18[0-9])|(17[0,1,3,5,6,7,8]))\d{8})$/g;
      if (!value) {
        this.disabled = true;
        return callback(new Error('请输入电话号码'));
      }
      if (!re.test(value)) {
        this.disabled = true;
        return callback(new Error('请输入合法的电话号码'));
      }
      if (value) {
        // 验证手机号是否注册过
        const result = await this.exsitLoginName(value);
        if (result) {
          this.disabled = true;
          callback(new Error('该号码已被注册'));
        } else {
          callback();
          this.disabled = false;
        }
      }
    };
    this.rules = {
      newPhone: [{ required: true, validator: validatePhone, trigger: 'change' }],
      authCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
    };
    return {
      disabled: true,
      formData: {},
      user: {
        tel: this.userInfo.agentUser ? this.userInfo.agentUser.lxdh : this.userInfo.tel,
      },
      loadingBtn: false,
    };
  },
  mounted() {
    this.user.tel = this.$decryptByDES(this.user.tel);
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loadingBtn = true;
          this.updataPhoneData();
        }
      });
    },
    close() {
      this.$emit('success');
    },
    // 更新手机号
    updataPhoneData() {
      const params = {
        loginName: this.userInfo.loginName,
        oriPhone: this.userInfo.tel,
        ...this.formData,
      };
      if (this.$hasPermission('IEBDC:DL:TYRZ')) {
        params.ticket = localDataUser.get() ? localDataUser.get().UNIFIED_AUTHORIZATION : '';
      }
      updataPhone(params).then((res) => {
        if (res && res.success) {
          this.$message.success('更新成功');
          // 更新缓存数据
          const userInfo = localDataUser.get();
          userInfo.tel = this.formData.newPhone;
          userInfo.userInfo.phone = this.formData.newPhone;
          this.loadingBtn = false;
          this.$emit('success');
        }
      });
    },
    // 验证手机号是否被注册过
    exsitLoginName(phone) {
      return new Promise((reslove) => {
        const strUrl =
          this.userInfo.userType === 'gr'
            ? REST_SERVICE.register.exsitGrLoginName
            : REST_SERVICE.register.exsitJgLoginName;
        const params = this.userInfo.userType === 'gr' ? { keyWord: phone } : { loginName: phone };
        fetchFun(strUrl, params).then((res) => {
          if (res && res.success) {
            reslove(res.data);
          } else {
            reslove(false);
          }
        });
      });
    },
  },
};
</script>
