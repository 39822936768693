<template>
  <div>
    <div class="operate" v-show="!showAddProCurator">
      <el-button type="primary" @click="addProCurator">添加代理人</el-button>
    </div>
    <div>
      <el-form v-show="showAddProCurator" label-width="130px" :model="formData" ref="form" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="代理人类型">
              <dict-select :disabled="disabled" v-model="formData.dlrlx" type="代理人类型"></dict-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="formData.dlrlx === '1'">
          <el-col :span="12">
            <el-form-item label="代理机构名称" prop="orgName">
              <el-input :disabled="disabled" v-model="formData.orgName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="代理机构电话" prop="orgPhone">
              <el-input :disabled="disabled" v-model="formData.orgPhone"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="formData.dlrlx === '0' ? '代理人名称' : '代表人名称'" prop="name">
              <el-input :disabled="disabled" v-model="formData.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="formData.dlrlx === '0' ? '联系电话' : '代表人电话'" prop="phoneNo">
              <el-input :disabled="disabled" v-model="formData.phoneNo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="formData.dlrlx === '0' ? '代理人证件种类' : '代表人证件种类'">
              <dict-select disabled v-model="formData.dlrzjzl" type="证件种类"></dict-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="formData.dlrlx === '0' ? '代理人证件号码' : '代表人证件号码'" prop="idNo">
              <el-input :disabled="disabled" v-model="formData.idNo"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="text-center">
          <el-button type="primary" @click="submit" v-if="!disabled">
            确定
          </el-button>
          <el-button type="primary" @click="showAddProCurator = false">
            返回
          </el-button>
        </el-form-item>
      </el-form>
      <e-table
        v-show="!showAddProCurator"
        :columns="columns"
        :options="options"
        :operates="operates"
        :tableList="tableData"
      ></e-table>
    </div>
  </div>
</template>

<script>
import { addAgent, getAgentsByUserId, updateAgent, deleteAgent } from 'iebdc/api/user-center/my-procurator';
import { validateName, validateZjh, validatePhone } from 'iebdc/utils/validate';
export default {
  name: 'my-procurator',
  props: {
    userId: {
      type: String
    }
  },
  data() {
    this.rules = {
      orgName: [{ required: true, message: '请输入代理机构', trigger: 'blur' }],
      orgPhone: [{ required: true, validator: validatePhone, trigger: 'blur' }],
      name: [{ required: true, validator: validateName, trigger: 'blur' }],
      phoneNo: [{ required: true, validator: validatePhone, trigger: 'blur' }],
      idNo: [{ required: true, validator: validateZjh(), trigger: 'blur' }]
    };
    return {
      disabled: false,
      formData: {
        dlrlx: '0',
        dlrzjzl: '1',
        userId: this.userId
      },
      proCuratorId: '',
      tableData: [],
      showAddProCurator: false,
      columns: [
        {
          label: '代理人名称',
          prop: 'name'
        },
        {
          label: '联系电话',
          prop: 'phoneNo'
        },
        {
          label: '身份证号',
          prop: 'idNo'
        }
      ],
      options: {
        hasIndex: false
      },
      operates: {
        list: [
          {
            show: true,
            spanType: 'button',
            type: 'success',
            label: '查看',
            showCallback: () => true,
            method: this.viewProCurator
          },
          {
            show: true,
            spanType: 'button',
            type: 'warning',
            label: '编辑',
            showCallback: () => true,
            method: this.editProCurator
          },
          {
            show: true,
            spanType: 'button',
            label: '删除',
            type: 'danger',
            showCallback: () => {
              return true;
            },
            method: this.deleteProCurator
          }
        ]
      }
    };
  },
  mounted() {
    this.getProCurator();
  },
  methods: {
    // 获取代理人
    getProCurator() {
      getAgentsByUserId({ userId: this.userId }).then((res) => {
        this.tableData = res.data;
      });
    },
    // 添加代理人
    addProCurator() {
      this.formData = {
        dlrlx: '0',
        dlrzjzl: '1',
        userId: this.userId
      };
      this.$refs.form.clearValidate();
      this.proCuratorId = '';
      this.showAddProCurator = true;
      this.disabled = false;
    },
    // 验证表单
    submit() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res = null;
          if (this.proCuratorId) {
            // 编辑
            res = await this.saveEditProCurator();
          } else {
            // 新增
            res = await this.saveProCurator();
          }
          if (res && res.success) {
            this.$message.success('保存成功');
            this.getProCurator();
            this.$refs.form.resetFields();
            this.showAddProCurator = false;
          }
        }
      });
    },
    // 保存代理人,
    saveProCurator() {
      return new Promise((reslove) => {
        addAgent(this.formData).then((res) => {
          if (res && res.success) {
            reslove(res);
          }
        });
      });
    },
    // 获取表格中所选代理人的信息并打开详情页
    getTableRowData(row) {
      if (!row) return;
      const needKey = ['orgName', 'name', 'phoneNo', 'idNo', 'orgPhone'];
      for (const item of needKey) {
        if (row['orgName']) {
          this.$set(this.formData, 'dlrlx', '0');
        }
        if (row[item]) {
          this.$set(this.formData, item, row[item]);
        }
      }
      this.showAddProCurator = true;
    },
    // 查看代理人信息
    viewProCurator(index, row) {
      this.getTableRowData(row);
      this.disabled = true;
    },
    // 打开编辑代理人信息页面
    editProCurator(index, row) {
      this.getTableRowData(row);
      this.disabled = false;
      this.proCuratorId = row.id;
    },
    // 保存修改后的代理人信息
    saveEditProCurator() {
      return new Promise((reslove) => {
        const params = {
          ...this.formData,
          agentId: this.proCuratorId
        };
        updateAgent(params).then((res) => {
          reslove(res);
        });
      });
    },
    // 删除代理人
    deleteProCurator(index, { id }) {
      this.$confirm('确定要删除该代理人吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteAgent({ agentId: id }).then((res) => {
          if (res && res.success) {
            this.$message.success('删除成功');
            this.getProCurator();
          }
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.operate {
  text-align: right;
  margin-bottom: 10px;
}
</style>
