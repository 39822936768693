<template>
   <el-dialog :modal-append-to-body="false" title="选择设备" :visible.sync="dialogVisible" width="80%">
    <div>
      <el-tabs v-model="activeName1">
        <el-tab-pane label="新增设备" name="addEquipment">
          <el-form :model="equipment" :rules="rules" ref="equipment" class="demo-ruleForm">
            <el-row type="flex" justify="space-between">
              <el-col :span="7">
                <el-form-item label="设备编号" label-width="100px">
                  <el-input :disabled="equipment.deviceIp !== ''" v-model="equipment.deviceBh"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="设备IP" prop="deviceIp" label-width="100px">
                  <el-input :disabled="equipment.deviceBh !== ''" v-model="equipment.deviceIp"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="设备命名" prop="deviceName" label-width="100px">
                  <el-input v-model="equipment.deviceName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item>
                  <el-button type="primary" @click="submitEquipment('equipment')">新增</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div>
      <el-tabs v-model="activeName2">
        <el-tab-pane label="选择设备" name="choiceEquipment">
          <el-table :data="deviceList" stripe style="width: 100%">
            <el-table-column label="选择版本" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-radio
                  class="radio"
                  v-model="deviceRadio"
                  :label="scope.row.deviceBh ? scope.row.deviceBh : scope.row.deviceIp"
                  @change.native="getRowMessage(scope.row)"
                ></el-radio>
              </template>
            </el-table-column>
            <el-table-column prop="deviceName" label="设备名称" width="180"></el-table-column>
            <el-table-column prop="deviceBh" label="设备编号" width="180"></el-table-column>
            <el-table-column prop="deviceIp" label="设备IP" width="180"></el-table-column>
            <!--              <el-table-column prop="remainCount" label="剩余次数"></el-table-column>-->
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <!--                  <el-button type="text" size="small" @click="recharge(scope.row)">充值</el-button>-->
                <el-button type="text" size="small" @click="deleteMachine(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closed">取 消</el-button>
      <el-button type="primary" @click="updateDevice">确 定</el-button>
    </span>
   </el-dialog>
</template>

<script>
import {
  deviceRegister,
  getDeviceList,
  getUsageByDeviceId,
  deleteDeviceById,
  updateSenseDevice,
} from '@iebdc/api/user-center/device.js';
import { updateFinacialInfo } from 'iebdc/api/user-center/update-userinfo';
import { crypto } from '@/pages/iebdc/utils/crypto.js';
import { localDataUser } from '@iebdc/utils/local-data';
import { tr } from 'date-fns/locale';

export default {
  props: ['integratedMachine'],
  data() {
    return {
      dialogVisible: false,
      activeName1: 'addEquipment',
      activeName2: 'choiceEquipment',
      equipment: {
        deviceBh: '',
        deviceIp: '',
        deviceName: '',
        deviceUser: '',
      },
      rules: {
        deviceName: [{ required: true, message: '请输入设备命名', trigger: 'change' }],
      },
      deviceList: [],
      deviceRadio: '',
      deviceData: {},
      userInfo: localDataUser.get(),
      chooseDeviceInfo: {
        //选中的当前设备信息
        deviceBh: '',
        deviceIp: '',
        deviceId: '',
      },
    };
  },
  watch: {
    deviceData: function (val) {},
  },
  methods: {
    open() {
      this.dialogVisible = true;
      this.equipment.deviceUser = this.integratedMachine.id ? this.integratedMachine.id : '';
      this.getDevice();
    },
    closed() {
      this.dialogVisible = false;
    },
    submitEquipment(equipment) {
      this.$refs[equipment].validate((valid) => {
        if (valid) {
          if (this.equipment.deviceBh === '' && this.equipment.deviceIp === '')
            return this.$message.warning('编号与IP请至少输入一者！');
          deviceRegister(this.equipment).then((res) => {
            if (res && res.success) {
              this.$message.success('添加设备信息成功');
              this.getDevice();
            } else {
              this.$message.warning(res.message);
            }
          });
        } else {
          this.$message.warning('请完善表单');
        }
      });
    },
    async getDevice() {
      const res = await getDeviceList({ deviceUser: this.equipment.deviceUser });
      if (res && res.success) {
        if (res.data) {
          this.deviceList = res.data;
          res.data.forEach((item, index) => {
            if (item.defaultStatus === 0) {
              this.deviceRadio = res.data[index].deviceBh ? res.data[index].deviceBh : res.data[index].deviceIp;
              this.deviceData = res.data[index];
            }
          });
        } else {
          this.deviceList = [];
        }
        // let data = res.data;
        // let idArray = [];
        // for (let i = 0; i < data.length; i++) {
        //   idArray.push(data[i].deviceBh);
        // }
        // if (idArray.length > 0) {
        //   const result = await getUsageByDeviceId({ deviceIds: idArray });
        //   if (res.data.length !== 0) {
        //     for (let j = 0; j < result.data.length; j++) {
        //       res.data[j].remainCount = result.data[j].remainCount;
        //     }
        //   }
        //   this.deviceList = res.data;
        //   // console.log(this.deviceList, 'deviceList');
        // } else {
        //   this.deviceList = [];
        // }
      }
    },
    // 删除某个设备
    deleteMachine(row) {
      deleteDeviceById({ id: row.id }).then((res) => {
        if (res && res.success) {
          this.$message.success('删除成功');
          if (row.id === this.deviceRadio) {
            this.chooseDeviceInfo.deviceBh = '';
            this.chooseDeviceInfo.deviceIp = '';
            this.chooseDeviceInfo.deviceId = '';
            localStorage.setItem('chooseDeviceInfo', JSON.stringify(this.chooseDeviceInfo));
            // localDataUser.set(this.userInfoData);
          }
          this.getDevice();
        }
      });
    },
    // 充值
    // recharge(row) {
    //   let url =
    //     window.CONFIG.OPEN_LOGIN_PATH +
    //     '?logout=1&appKey=OPEN_PLATFORM&service=' +
    //     window.CONFIG.OPEN_UI_PATH +
    //     '?bh=' +
    //     crypto.encryptByDES(row.deviceBh);
    //   window.open(url);
    // },
    //获取radio行数据
    getRowMessage(row) {
      this.deviceData = row;
      // this.userInfo.id = row.id;
    },
    //更新设备信息
    updateDevice() {
      if (this.deviceData.id === '' || Object.keys(this.deviceData).length === 0) {
        return this.$message.warning('请先选择设备编号');
      }
      updateSenseDevice({ id: this.deviceData.id }).then((res) => {
        if (res && res.data) {
          this.chooseDeviceInfo.deviceBh = this.deviceData.deviceBh;
          this.chooseDeviceInfo.deviceIp = this.deviceData.deviceIp;
          this.chooseDeviceInfo.deviceId = this.deviceData.id;
          localStorage.setItem('chooseDeviceInfo', JSON.stringify(this.chooseDeviceInfo));
          console.log('this: ', this);
          this.$emit('updateSuccess');
          this.$message.success('修改成功');
      
          this.$emit('machineChange', false);
          location.reload();
          // const userInfo = localDataUser.get();
          // let params = {
          //   userId: userInfo.id,
          //   frmc: userInfo.frmc,
          //   frzjh: userInfo.frzjh
          // };
          //将文件信息存入user中
          // updateFinacialInfo(params).then((res) => {
          //   if (res && res.success) {
          //     this.userInfo.deviceBh = res.data.deviceBh
          //     this.userInfo.deviceIp = res.data.deviceIp;
          //     this.userInfo.deviceId = res.data.id;
          //     localDataUser.set(this.userInfo);
          //     this.$message.success('修改成功');
          //     this.$parent.user.deviceBh = res.data.deviceBh;
          //     this.dialogVisible = false;
          //   }
          // });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  .el-dialog__header {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    height: 44px !important;
    background-color: #f3f3f3;
    .el-dialog__title {
      color: #333333;
      font-size: 16px;
      font-weight: 400;
    }
    button {
      top: 12px;
      .el-icon-close {
        color: #ffffff;
        font-size: 22px;
      }
    }
  }
  .el-dialog__body {
    padding: 35px 50px;
    max-height: 360px;
    overflow-y: auto;
    &::-webkit-scrollbar-track-piece {
      background: #d3dce6;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #99a9bf;
    }
  }
  .el-dialog__footer {
    padding-bottom: 38px;
    text-align: center;
  }
  /deep/.public-el-button-default,
  .public-el-button {
    border-radius: 5px;
  }
  /deep/ .el-date-editor.el-input,
  .el-select,
  .el-cascader {
    width: 100%;
  }
  /deep/ .el-form-item__label {
    padding: 0 12px;
  }
  .line {
    text-align: center;
  }
}
</style>
