import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 权属证明查询
// export const qszmCheck = (params) =>
//   request({
//     url: REST_SERVICE.qszm.qszmCheck,
//     method: 'post',
//     data: params
//   });

// 获取办事大厅详情
export const bsdtDetail = (params) =>
  request({
    url: REST_SERVICE.uums.bsdtDetail,
    method: 'get',
    params,
  });

// 新权属证明查询--个人
export const checkCertifyInspect = (params) =>
  request({
    url: REST_SERVICE.qszm.checkCertifyInspect,
    method: 'post',
    params,
  });

// 新权属证明查询--企业
export const queryCompanySearchShare = (params) =>
  request({
    url: REST_SERVICE.qszm.queryCompanySearchShare,
    method: 'post',
    params,
  });

// 查询办事大厅
export const getWorkOffice = (params) =>
  request({
    url: REST_SERVICE.uums.getWorkOffice,
    method: 'get',
    params,
  });
export const getNewFwCdzm = (data) =>
  request({
    url: REST_SERVICE.qszm.getNewFwCdzm,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
// 同登记系统权属证明查询
export const getNewFwCdzmDetail = (data) =>
  request({
    url: REST_SERVICE.qszm.getNewFwCdzmDetail,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    data,
  });
// 查看是否有权限
export const getPermission = (params) =>
  request({
    url: REST_SERVICE.qszm.getPermission,
    method: 'get',
    params,
  });
// 5.8web和公众号查档证明
// export const checkCertifyInspectWeb = (params) =>
//   request({
//     url: REST_SERVICE.qszm.checkCertifyInspectWeb,
//     method: 'get',
//     params
//   });
