<template>
  <div class="my-authorization">
    <div class="btn">
      <el-button type="primary" class="sq-button" v-if="userType !== 'FINANCIAL'" @click="add">添加授权</el-button>
    </div>
    <e-search
      style="padding: 8px 0"
      :filterGroup="filterGroup"
      :btnsConfig="btnsConfig"
      :EDialogConfig="EDialogConfig"
      @search="search"
      @reset="reset"
    ></e-search>
    <e-table
      v-loading="loadingSq"
      @afterCurrentPageClick="myAuthorizationPage"
      ref="myAuthorization"
      :columns="columns"
      :operates="operates"
      :options="options"
    ></e-table>
    <e-dialog :config="openSearchConfig" :btns="openSearchBtns">
      <template slot="content">
        <e-table :tableList="houseTableList" :options="houseOptions" :columns="houseColumns" />
      </template>
    </e-dialog>
  </div>
</template>

<script>
import authorizationSearch from '@/pages/iebdc/api/authorization-search';
import { localDataUser } from '@/pages/iebdc/utils/local-data';

export default {
  name: 'my-authorization',
  components: {},
  props: {
    addEmpowerInfo: Function
  },
  data() {
    return {
      loadingSq: true,
      userType: '',
      params: {},
      user: '',
      needUpdataKey: 0, //刷新视图
      bankUsesrList: [],
      btnsConfig: {
        showResetBtn: true,
        clearBtnText: '重置'
      },
      filterGroup: [
        {
          width: '120px',
          label: '被授权方名称',
          type: 'select',
          placeholder: '请选择',
          key: 'userName',
          options: this.bankUsesrList,
          props: {
            label: 'name',
            value: 'name'
          },
          value: ''
        },
        {
          type: 'dicSelect',
          dicType: '授权状态',
          label: '授权状态',
          placeholder: '请选择',
          key: 'status',
          value: ''
        },
        {
          label: '授权起止时间',
          type: 'dateRange',
          centerLabel: false,
          options: [
            {
              key: 'startTime',
              value: '',
              valueformat: 'yyyy-MM-dd',
              placeholder: '请选择'
            },
            {
              key: 'endTime',
              value: '',
              valueformat: 'yyyy-MM-dd',
              placeholder: '请选择'
            }
          ]
        }
      ],
      EDialogConfig: {
        visible: false, //默认不显示更多
        maxLength: 5,
        name: 'authorization'
      },
      tableList: [],
      columns: [
        {
          prop: 'authorizeName',
          label: '被授权方名称'
        },
        {
          prop: 'ctime',
          label: '授权开始时间',
          type: 'date'
        },
        {
          prop: 'endTime',
          label: '授权结束时间',
          type: 'date'
        },
        {
          prop: 'validDay',
          label: '有效期',
          type: 'dic',
          dicType: '授权有效期'
        },
        {
          width: '120px',
          prop: 'status',
          label: '授权状态',
          type: 'dic',
          dicType: '授权状态',
          formatter: (scope) => {
            const status = this.$store.getters.getLabelDicByTypeAndValue('授权状态', scope.status);
            if (status === '授权中') {
              return `<span style="color:rgba(25, 145, 0, 100); ">${status}</span>`;
            } else {
              return `<span>${status}</span>`;
            }
          }
        }
      ],
      operates: {
        //表格操作栏
        fixed: 'right',
        list: [
          {
            show: true,
            spanType: 'button',
            id: '1',
            label: '查看',
            type: 'text',
            size: 'medium',
            color: 'success',
            method: (key, row) => {
              this.getDetail(row);
            },
            showCallback: () => {
              return true;
            }
          }
        ]
      },
      options: {
        // 每页数据数
        pageSize: 10,
        // 当前页码
        currentPage: 1,
        loading: true,
        mutiSelect: false,
        hasIndex: false,
        maxHeight: '5000px'
      },
      openSearchConfig: {
        title: '已授权的不动产详情',
        width: '900px',
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: true
      },
      openSearchBtns: [
        {
          label: '返回',
          class: 'public-el-button-default',
          method: () => {
            this.openSearchConfig.visible = false;
          },
          show: true
        }
      ],
      houseTableList: [],
      houseOptions: {
        hasIndex: true,
        height: '320px',
        maxHeight: '556px',
        hasPagination: false
      },
      houseColumns: [
        {
          prop: 'bdcqzh',
          label: '不动产权证号'
        },
        {
          prop: 'qlrmc',
          label: '权利人名称'
        },
        {
          prop: 'qlrzjh',
          label: '证件号'
        },
        {
          prop: 'djsj',
          label: '登记时间'
        },
        {
          prop: 'fwzt',
          label: '房屋状态',
          formatter: (scope) => {
            // 拼接是否查封，是否抵押
            let sfcf = '';
            let sfdy = '';
            if (scope.sfcf === '0' || !scope.sfcf) {
              sfcf = '未查封';
            } else {
              sfcf = '已查封';
            }
            if (scope.sfdy === '0' || !scope.sfdy) {
              sfdy = '未抵押';
            } else {
              sfdy = '已抵押';
            }
            return `<span>${sfcf}${sfdy}</span>`;
          }
        },
        {
          prop: 'djjg',
          label: '登记机构'
        }
      ]
    };
  },
  methods: {
    myAuthorizationPage(value) {
      this.$refs.myAuthorization.setCurrentPage(value);
      this.params.nCurrent = value - 1;
      this.query();
    },
    // 初始化数据
    init() {
      this.user = Object.assign({}, localDataUser.get());
      this.userType = this.user.userCategory;
      this.params = {
        nCurrent: 0,
        userId: this.user.id,
        userCategoryEnum: this.user.userCategory
      };
      this.query();
      this.getBankUser();
    },
    query() {
      this.loadingSq = true;
      authorizationSearch
        .authorizeGetByPage(this.params)
        .then((res) => {
          if (res.success && res.data) {
            this.$refs.myAuthorization.setTableList(res.data.page);
            this.$refs.myAuthorization.setTotal(res.data.recordCount);
          } else {
            this.$message.error(res.message);
          }
          this.loadingSq = false;
        })
        .catch(() => {
          this.loadingSq = false;
        });
    },
    // 获取银行企业列表
    getBankUser() {
      authorizationSearch.authorizeBankUserGet().then((res) => {
        if (res.success && res.data) {
          this.bankUsesrList = [...res.data];
          this.filterGroup.map((item) => {
            if (item.label === '被授权方名称') {
              this.$set(item, 'options', this.bankUsesrList);
            }
          });
        }
      });
    },
    add() {
      this.$emit('addEmpowerInfo');
    },
    // 查询
    search(queryParams) {
      console.log(queryParams, '参数');
      this.params = Object.assign(this.params, queryParams, { nCurrent: 0 });
      this.query();
      this.$refs.myAuthorization.setCurrentPage(1);
    },
    // 清除(重置)
    reset() {
      this.init();
      this.$refs.myAuthorization.setCurrentPage(1);
    },
    //查看具体被授权信息
    getDetail(row) {
      this.openSearchConfig.visible = true;
      this.$nextTick(() => {
        this.houseTableList = [...row.conditionList];
      });
    }
  },
  created() {
    this.init();
  }
};
</script>

<style scoped lang="scss">
.btn {
  position: absolute;
  width: 100%;
  .sq-button {
    position: absolute;
    right: 35px;
    // left: 0px;
    top: 48px;
    z-index: 99; //position:absolute绝对定位后，onclick事件失效问题解决
  }
}
/deep/ .e-table {
  position: relative !important;
  .el-table__body-wrapper {
    height: auto !important;
  }
}
</style>
