<template>
  <table class="table table-bordered table-bd">
    <tbody>
      <tr>
        <td class="blue-label">
          <label>适用情形</label>
        </td>
        <td colspan="7">{{ data.description }}</td>
      </tr>
      <tr>
        <td class="blue-label">
          <label>承诺时限</label>
        </td>
        <td colspan="3">{{ data.cnqx }}</td>
        <td class="blue-label">
          <label>法定时限</label>
        </td>
        <td colspan="3">{{ data.cnqx }}</td>
      </tr>
      <tr>
        <td class="blue-label">
          <label>咨询电话</label>
        </td>
        <td colspan="3">{{ data.zxdh }}</td>
        <td class="blue-label">
          <label>投诉电话</label>
        </td>
        <td colspan="3">{{ data.tsdh }}</td>
      </tr>
      <tr>
        <td class="blue-label">
          <label>预审时限</label>
        </td>
        <td colspan="7">{{ data.yssx }}工作日</td>
      </tr>
      <tr>
        <td class="blue-label">
          <label>受理条件</label>
        </td>
        <td colspan="7">{{ data.sltj }}</td>
      </tr>
      <tr>
        <td class="blue-label">
          <label>收费标准</label>
        </td>
        <td colspan="7">{{ data.sfbz }}</td>
      </tr>
      <tr>
        <td class="blue-label">
          <label>法定依据</label>
        </td>
        <td colspan="7">{{ data.fdyj }}</td>
      </tr>
      <tr>
        <td class="blue-label">
          <label>申报材料</label>
        </td>
        <td colspan="7" class="istable">
          <table>
            <thead>
              <th style="min-width: 100px;">材料名称</th>
              <th style="width: 100px;">材料形式</th>
              <th style="min-width: 200px;">材料详细要求</th>
              <th style="width: 80px;">是否必需</th>
              <th style="width: 50px;">示例</th>
            </thead>
            <tbody>
              <tr v-for="(item, index) in documentList" :key="index">
                <td>{{ item.clmc }}</td>
                <td>{{ item.bz }}</td>
                <td>{{ item.clsm }}</td>
                <td>{{ item.sfbx }}</td>
                <td>
                  <a @click="getDocumentStyle(item)" v-if="item.clsls && item.clsls.length > 0">示例</a>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { showGuide, showFiles } from 'iebdc/api/visiting-service';
export default {
  name: 'MatterInfo.vue',
  props: {
    //办事指南
    data: { type: Object },
    //申请材料
    documentList: { type: Array },
  },
  methods: {
    // 获取办事指南
    showGuide(id) {
      const params = {
        subcfgId: id
      };
      // showGuide(params).then((res) => {
      //   if (res && res.success) {
      //     this.data = res.data;
      //   }
      // });
      // showFiles(params).then((res) => {
      //   if (res && res.success) {
      //     this.documentList = res.data;
      //     for (const item of this.documentList) {
      //       if (item.sfbx === true) {
      //         item.sfbx = '是';
      //       } else {
      //         item.sfbx = '否';
      //       }
      //     }
      //   }
      // });
      Promise.all([showGuide(params), showFiles(params)]).then(res => {
          if(res[0].success && res[0].data) {
            this.data = res[0].data;
          }
          if(res[1].success && res[1].data) {
          for (const item of res[1].data) {
            if (item.sfbx === true) {
              item.sfbx = '是';
            } else {
              item.sfbx = '否';
            }
          }
          this.documentList = res[1].data;
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
// table {
//   border-spacing: 0;
//   border-collapse: collapse;
// }
// .table-bd {
//   height: 100%;
//   tr td {
//     padding: 8px;
//     border: 1px solid #dddddd;
//   }
// }
// .table-bordered,
// .table-bordered td,
// .table-bordered th {
//   border: 1px solid #ddd;
// }
// .blue-label {
//   width: 12.5%;
//   text-align: center;
//   background-color: #eaf1f9;
//   vertical-align: middle;
// }
// .table {
//   width: 100%;
//   margin-bottom: 20px;
//   td,
//   th {
//     padding: 8px;
//     line-height: 1.53846154;
//     vertical-align: top;
//     border-bottom: 1px solid #ddd;
//     -webkit-transition: background 0.2s cubic-bezier(0.175, 0.885, 0.32, 1);
//     -o-transition: background 0.2s cubic-bezier(0.175, 0.885, 0.32, 1);
//     transition: background 0.2s cubic-bezier(0.175, 0.885, 0.32, 1);
//   }
// }
// label {
//   display: inline-block;
//   font-weight: 700;
// }
.table {
  line-height: 24px;
  padding-top: 10px;
  border: 1px solid #c9deef;
  border-collapse: collapse;
}
.table-bd {
  color: #353535;
  height: 100%;
  tr {
    height: 38px;
    td {
      padding: 8px;
      border: 1px solid #dddddd;
      a {
        cursor: pointer;
      }
    }
  }
  > td {
    width: 25%;
    vertical-align: middle;
  }
  .blue-label {
    width: 12.5%;
    text-align: center;
    background-color: #eaf1f9;
    vertical-align: middle;
    label {
      margin: 0px;
      font-weight: 700;
    }
  }
  .istable {
    padding: 0px;
    table {
      width: 100%;
      border: none;
      border-collapse: collapse;
      th {
        border-right: 1px solid #dddddd;
        padding: 8px;
        background-color: #eaf1f9;
      }
      td {
        border: none;
        border-right: 1px solid #dddddd;
        border-top: 1px solid #dddddd;
      }
      th:last-child {
        border: none;
      }
    }
  }
}
</style>
