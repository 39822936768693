<template>
  <el-form label-width="150px" :model="formData" ref="form">
    <el-form-item label="企业名称" prop="corpName" :rules="[rules.required]">
      <el-input v-model="formData.corpName"></el-input>
    </el-form-item>
    <el-form-item label="统一信用社代码" prop="certificateSno" :rules="[rules.required, rules.shxy]">
      <el-input v-model="formData.certificateSno"></el-input>
    </el-form-item>
    <el-form-item label="法人名称" prop="frmc" :rules="[rules.required]">
      <el-input v-model="formData.frmc"></el-input>
    </el-form-item>
    <!-- <el-form-item label="法人手机号" prop="frdh" :rules="[rules.required, rules.phone]">
      <el-input v-model="formData.frdh"></el-input>
    </el-form-item> -->
    <el-form-item label="法人身份证号" prop="frzjh" :rules="[rules.required, rules.idcard]">
      <el-input v-model="formData.frzjh"></el-input>
    </el-form-item>
    <el-form-item label="身份证明" :rules="[rules.required]">
      <el-upload
        :data="uploadParams"
        name="file"
        :action="submitFileUrl"
        accept="jpg, png"
        :file-list="formData.uploadfiles"
        :before-upload="beforeUpload"
        :on-success="uploadSuccess"
        list-type="picture-card"
      >
        <i class="el-icon-plus"></i>
        <div class="upload-img" slot="file" slot-scope="{ file }">
          <img class="el-upload-list__item-thumbnail" :src="file.fileUrl" alt="" />
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
            <span class="el-upload-list__item-delete" @click="removeFile(file)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </el-form-item>
    <el-form-item class="text-center">
      <el-button type="primary" @click="submit">确认修改</el-button>
      <el-button type="primary" @click="close">关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import hasPermission from 'iebdc/mixin/hasPermission';
import { fileDetail } from 'iebdc/api/file-service';
import { updateFinacialInfo } from 'iebdc/api/user-center/update-userinfo';
import { localDataUser } from '@iebdc/utils/local-data';
import { REST_SERVICE } from 'iebdc/config/';
export default {
  name: 'update-legal-person',
  mixins: [hasPermission],
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {
        userCategory: this.userInfo.userCategory,
        djjg: this.userInfo.djjg,
        corpName: this.userInfo.name,
        certificateSno: this.userInfo.qyzjh,
        frmc: this.userInfo.frmc,
        frdh: this.userInfo.frdh,
        frzjh: this.userInfo.frzjh,
        uploadfiles: this.userInfo.uploadfiles ? this.userInfo.uploadfiles : [],
      },
      dialogImageUrl: '',
      dialogVisible: false,
      uploadParams: {},
      submitFileUrl: REST_SERVICE.files.mulFileSave,
      rules: this.rules,
    };
  },

  methods: {
    close() {
      this.$emit('success');
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.fileUrl;
      this.dialogVisible = true;
    },
    // 移除文件
    removeFile(fileData) {
      console.log(fileData.url);
      // 在上传文件列表里找到改文件
      this.formData.uploadfiles.map((item, i) => {
        if (item.id === fileData.id && item.url === fileData.url) {
          this.formData.uploadfiles.splice(i, 1);
        }
      });
    },
    // 在这里上传文件
    beforeUpload(file) {
      let isExist = false;
      // 判断文件是否重复
      if (this.formData.uploadfiles.length > 0) {
        isExist = this.formData.uploadfiles.find((item) => item.name === file.name);
      }
      if (isExist) {
        this.$message.warning('上传文件的文件名不可重复！');
        return Promise.reject();
      }
      console.log(this.formData, isExist, file, 'f');
      //文件名命名规范
      // let filetypes = ['~', '!', '@', '#', '$', '%', '^', '&', '*', '|', '-', ' '];
      // let filename = file.name.substring(0, file.name.lastIndexOf('.'));
      // for (let item of filetypes) {
      //   if (filename.indexOf(item) > 0) {
      //     item = item === ' ' ? '空格' : item;
      //     this.$message.warning(`上传文件的文件名不可包含${item}等特殊字符！`);
      //     return Promise.reject();
      //   }
      // }
      // const res = await getSystemTime();
      // const systemTime = res.data;
      const systemTime = Date.parse(new Date());
      this.uploadParams = {
        dir: `qyzc/${localDataUser.get().loginName}/${systemTime}`,
        recover: true,
      };
    },
    // 文件上传成功
    uploadSuccess(res) {
      if (res && res.success) {
        const { data } = res;
        this.formData.uploadfiles.push({
          id: data[0].info.id,
          name: data[0].info.fileName,
          url: data[0].info.filePathUrl,
          fileUrl: data[0].info.filePathUrl,
          type: data[0].info.fileType,
        });
      }
    },
    // 验证表单
    submit() {
      if (this.formData.uploadfiles.length <= 0) {
        this.$message.error('请上传身份认证材料');
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.save();
        }
      });
    },
    // 保存修改后的信息
    save() {
      const fileId = this.formData.uploadfiles.map((item) => item.id);
      const fileIds = fileId.join(',');
      const params = {
        userId: this.userInfo.id,
        frmc: this.formData.frmc,
        frzjh: this.formData.frzjh,
        certificateSno: this.formData.certificateSno,
        corpName: this.formData.corpName,
        fileIds,
      };
      if (this.$hasPermission('IEBDC:DL:TYRZ')) {
        params.ticket = localDataUser.get() ? localDataUser.get().UNIFIED_AUTHORIZATION : '';
      }
      updateFinacialInfo(params).then((res) => {
        if (res && res.success) {
          // 更新缓存数据
          const userInfo = localDataUser.get();
          userInfo.frmc = this.formData.frmc;
          userInfo.frdh = this.formData.frdh;
          userInfo.frzjh = this.formData.frzjh;
          userInfo.certificateSno = this.formData.certificateSno;
          userInfo.corpName = this.formData.corpName;
          userInfo.fileIds = fileId;
          localDataUser.set(userInfo);
          this.$emit('success');
          this.$message.success('修改成功');
        }
      });
    },
    fileId() {
      if ('fileIds' in this.userInfo) {
        if (this.userInfo.fileIds) {
          this.userInfo.fileIds.forEach((id) => {
            fileDetail({ fileId: id }).then((res) => {
              this.formData.uploadfiles.push({
                id: id,
                fileUrl: res.data,
              });
            });
          });
        }
      }
    },
  },
  created() {
    this.fileId();
  },
};
</script>

<style lang="scss" scoped>
.upload-img {
  line-height: 150px;
}
</style>
