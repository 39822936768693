import request from '../utils/request';
import { REST_SERVICE } from '../config';
// import { copyLayoutParams } from 'echarts/src/util/layout';

// 查询 我的申请
export const getAppointmentPageList = (params) =>
  request({
    url: REST_SERVICE.myAppointment.getAppointmentPageList,
    method: 'get',
    params
  });

//查看 我的预约信息
export const getAppointmentInfoByCode = (params) =>
  request({
    url: REST_SERVICE.myApplication.getAppointmentInfoByCode,
    method: 'get',
    params
  });

//查看 我的预约信息
export const cancelAppointment = (params) =>
  request({
    url: REST_SERVICE.myApplication.cancelAppointment,
    method: 'post',
    data: params
  });

//根据id查询办事指南
export const getGuideInfo = {
  getGuideInfoFn: function(params) {
    return request({
      url: REST_SERVICE.myApplication.getGuideInfo,
      method: 'get',
      params
    });
  }
};

//根据id查询材料列表
export const getMaterialsInfo = {
  getMaterialsInfoFn: function(params) {
    return request({
      url: REST_SERVICE.myApplication.getMaterialsInfo,
      method: 'get',
      params
    });
  }
};
