import request from 'iebdc/utils/request';
import { REST_SERVICE } from 'iebdc/config';

// 更新用户密码
export const updataPwd = (params) =>
  request({
    url: REST_SERVICE.user.updataPwd,
    method: 'post',
    data: params,
  });
// 更新用户手机号
export const updataPhone = (params) =>
  request({
    url: REST_SERVICE.user.updataPhone,
    method: 'post',
    data: params,
  });
// 更新法人信息
export const updateFinacialInfo = (params) =>
  request({
    url: REST_SERVICE.user.updateFinacialInfo,
    method: 'post',
    params,
  });
