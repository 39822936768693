<template>
  <div class="container">
    <div class="left">
      <div class="img-box" style="text-align: center; display: flex; align-items: center; justify-content: center">
        <el-avatar :size="40" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
        <p class="big">{{ userName }}</p>
      </div>
      <div class="user-detail">
        <el-tabs v-model="activeName" :tab-position="tabPosition" @tab-click="applyClick(activeName)">
          <el-tab-pane label="我的信息" name="info">
            <span slot="label">
              <span class="span-box">
                <img v-show="activeName != 'info'" src="@/pages/iebdc/assets/images/userCenter/myInfo.png" alt="" />
                <img v-show="activeName == 'info'" src="@/pages/iebdc/assets/images/userCenter/myInfo1.png" alt="" />
                <span class="span-name">我的信息</span>
              </span>
            </span>
          </el-tab-pane>
          <!--<el-tab-pane label="我的申请" name="apply" v-if="hasPermission('IEBDC:SY:WDSQ')">-->
          <el-tab-pane label="我的申请" name="apply" v-if="hasPermission('IEBDC:WDCX')">
            <span slot="label">
              <span class="span-box">
                <img
                  v-show="
                    activeName != 'apply' &&
                    activeName != 'draft' &&
                    activeName != 'blzBox' &&
                    activeName != 'finishBox' &&
                    activeName != 'rollback'
                  "
                  src="@/pages/iebdc/assets/images/userCenter/myApply.png"
                  alt=""
                />
                <img
                  v-show="
                    activeName == 'apply' ||
                    activeName == 'draft' ||
                    activeName == 'blzBox' ||
                    activeName == 'finishBox' ||
                    activeName == 'rollback'
                  "
                  src="@/pages/iebdc/assets/images/userCenter/myApply1.png"
                  alt=""
                />
                <span class="span-name" style="margin-right: 5px">我的申请</span>
                <i v-show="!isApplyClick" class="el-icon-arrow-down span-name-icon"></i>
                <i v-show="isApplyClick" class="el-icon-arrow-up span-name-icon"></i>
              </span>
            </span>
          </el-tab-pane>
          <el-tab-pane label="草稿箱" name="draft" v-if="hasPermission('IEBDC:SY:WDSQ') && isApplyClick">
            <span slot="label">
              <span class="span-box">
                <span class="span-name-child">草稿箱</span>
              </span>
            </span>
          </el-tab-pane>
          <el-tab-pane label="办理中" name="blzBox" v-if="hasPermission('IEBDC:SY:WDSQ') && isApplyClick">
            <span slot="label">
              <span class="span-box">
                <span class="span-name-child">办理中</span>
              </span>
            </span>
          </el-tab-pane>
          <el-tab-pane label="办结箱" name="finishBox" v-if="hasPermission('IEBDC:SY:WDSQ') && isApplyClick">
            <span slot="label">
              <span class="span-box">
                <span class="span-name-child">办结箱</span>
              </span>
            </span>
          </el-tab-pane>
          <el-tab-pane label="回退箱" name="rollback" v-if="hasPermission('IEBDC:SY:WDSQ') && isApplyClick">
            <span slot="label">
              <span class="span-box">
                <span class="span-name-child">回退箱</span>
              </span>
            </span>
          </el-tab-pane>

          <el-tab-pane label="我的预约" name="appointment" v-if="hasPermission('IEBDC:SY:WDYY')"
            ><span slot="label">
              <span class="span-box">
                <img
                  v-show="activeName != 'appointment'"
                  src="@/pages/iebdc/assets/images/userCenter/myAppointment.png"
                  alt=""
                />
                <img
                  v-show="activeName == 'appointment'"
                  src="@/pages/iebdc/assets/images/userCenter/myAppointment1.png"
                  alt=""
                />
                <span class="span-name">我的预约</span>
              </span>
            </span></el-tab-pane
          >
          <el-tab-pane label="我的查询" name="query" v-if="hasPermission('IEBDC:SY:WDCK')"
            ><span slot="label">
              <span class="span-box">
                <img v-show="activeName != 'query'" src="@/pages/iebdc/assets/images/userCenter/myQuery.png" alt="" />
                <img v-show="activeName == 'query'" src="@/pages/iebdc/assets/images/userCenter/myQuery1.png" alt="" />
                <span class="span-name">我的查询</span>
              </span>
            </span></el-tab-pane
          >
          <el-tab-pane label="我的快递" name="express" v-if="hasPermission('IEBDC:SY:WDKD')"
            ><span slot="label">
              <span class="span-box">
                <img
                  v-show="activeName != 'express'"
                  src="@/pages/iebdc/assets/images/userCenter/my-express.png"
                  alt=""
                />
                <img
                  v-show="activeName == 'express'"
                  src="@/pages/iebdc/assets/images/userCenter/my-express1.png"
                  alt=""
                />
                <span class="span-name">我的快递</span>
              </span>
            </span></el-tab-pane
          >
          <el-tab-pane label="我的税务" name="tax" v-if="hasPermission('IEBDC:SY:WDSW')"
            ><span slot="label">
              <span class="span-box">
                <img v-show="activeName != 'tax'" src="@/pages/iebdc/assets/images/userCenter/my-tax.png" alt="" />
                <img v-show="activeName == 'tax'" src="@/pages/iebdc/assets/images/userCenter/my-tax1.png" alt="" />
                <span class="span-name">我的税务</span>
              </span>
            </span></el-tab-pane
          >
          <el-tab-pane label="我的代理人" name="procurator" v-if="hasPermission('IEBDC:SY:WDDLR')"
            ><span slot="label">
              <span class="span-box">
                <img
                  v-show="activeName != 'procurator'"
                  src="@/pages/iebdc/assets/images/userCenter/myOperator.png"
                  alt=""
                />
                <img
                  v-show="activeName == 'procurator'"
                  src="@/pages/iebdc/assets/images/userCenter/myOperator1.png"
                  alt=""
                />
                <span class="span-name">我的代理人</span>
              </span>
            </span></el-tab-pane
          >
          <el-tab-pane label="我的经办人" name="operator" v-if="hasPermission('IEBDC:SY:WDJBR')"
            ><span slot="label">
              <span class="span-box">
                <img
                  v-show="activeName != 'operator'"
                  src="@/pages/iebdc/assets/images/userCenter/myOperator.png"
                  alt=""
                />
                <img
                  v-show="activeName == 'operator'"
                  src="@/pages/iebdc/assets/images/userCenter/myOperator1.png"
                  alt=""
                />
                <span class="span-name">我的经办人</span>
              </span>
            </span></el-tab-pane
          >
          <el-tab-pane label="我的授权" name="authorization" v-if="hasPermission('IEBDC:SY:WDSQQ')"
            ><span slot="label">
              <span class="span-box">
                <img
                  v-show="activeName != 'authorization'"
                  src="@/pages/iebdc/assets/images/userCenter/myAuthorization.png"
                  alt=""
                />
                <img
                  v-show="activeName == 'authorization'"
                  src="@/pages/iebdc/assets/images/userCenter/myAuthorization1.png"
                  alt=""
                />
                <span class="span-name">我的授权</span>
              </span>
            </span></el-tab-pane
          >
          <el-tab-pane
            label="楼盘绑定"
            name="building"
            v-if="hasPermission('IEBDC:SY:LPBD') && user.userCategory !== 'NORMAL'"
          >
            <span slot="label">
              <span class="span-box">
                <img
                  v-show="activeName != 'building'"
                  src="@/pages/iebdc/assets/images/userCenter/myApply.png"
                  alt=""
                />
                <img
                  v-show="activeName == 'building'"
                  src="@/pages/iebdc/assets/images/userCenter/myApply1.png"
                  alt=""
                />
                <span class="span-name">楼盘绑定</span>
              </span>
            </span>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div class="right">
      <!-- 添加授权 -->
      <div class="user-info-update" v-if="updateType">
        <span>{{ updateInfoTip }}</span>
        <updatePassword v-if="updateType === 'pwd'" :userInfo="user" @success="updateSuccess" />
        <updatePhone v-if="updateType === 'phone'" :userInfo="user" @success="updateSuccess" />
        <updateLegalPerson v-show="updateType === 'legalPerson'" :userInfo="user" @success="updateSuccess" />
        <AddAuthorizationInfo v-if="updateType === 'empowerInfo'" @backTabs="backTabs" />
      </div>
      <!--  -->
      <div v-if="!updateType">
        <userCenterInfo :userGpy="userGpy" @userCenterInfoChange="userCenterInfoChange" v-if="activeName === 'info'" />
        <applicationMain v-if="activeName === 'apply'" />
        <state-box
          v-if="
            activeName === 'draft' || activeName === 'blzBox' || activeName === 'finishBox' || activeName === 'rollback'
          "
          :stateBoxData="stateBoxData"
        ></state-box>
        <appointmentMain v-if="activeName === 'appointment'" />
        <queryMain v-if="activeName === 'query'" />
        <myExpress v-if="activeName === 'express'" />
        <myProcurator :userId="user.id" v-if="activeName === 'procurator'" />
        <myOperator :userId="user.id" v-if="activeName === 'operator'" />
        <MyAuthorization
          v-if="activeName === 'authorization' && userType !== 'FINANCIAL'"
          @addEmpowerInfo="addEmpowerInfo"
        />
        <MyAuthorized v-if="activeName === 'authorization' && userType === 'FINANCIAL'" />
        <applyBuilding v-if="activeName === 'building' && userType === 'KFS'" />
        <taxList v-if="activeName === 'tax'" />
        <!-- 选择一体机 -->
        <integrated-machine
          ref="integrated"
          :integratedMachine="user"
          @updateSuccess="updateSuccess"
        ></integrated-machine>
      </div>
    </div>
  </div>
</template>

<script>
import { localDataUser } from '@iebdc/utils/local-data';
import appointmentMain from '../my-appointment/component/appointment-main';
import myOperator from '../my-operator';
import queryMain from '../my-query/component/query-main';
import taxList from '@/pages/iebdc/views/user-center-new/component/taxList';
import myExpress from '../my-express';
import myProcurator from '../user-center/component/my-procurator';
import hasPermission from 'iebdc/mixin/hasPermission';
import updatePassword from '../user-center/component/update-password';
import updatePhone from '../user-center/component/update-phone';
import updateLegalPerson from '../user-center/component/update-legal-person';
import applicationMain from '../my-application/component/application-main';
import IntegratedMachine from '../user-center/component/integrated';
// import { getDeviceList, saveCompany } from '@iebdc/api/user-center/device.js';
import { findUserCenter } from '@iebdc/api/user-server.js';
import MyAuthorization from '../user-center/component/my-authorization';
import AddAuthorizationInfo from '../user-center/component/add-authorization-info';
import MyAuthorized from '../user-center/component/my-authorized';
import userCenterInfo from './component/userCenterInfo';
import applyBuilding from '../apply-building';
import StateBox from './component/stateBox'; //状态箱

export default {
  name: 'user-center',
  mixins: [hasPermission],
  components: {
    MyAuthorization,
    myProcurator,
    taxList,
    myOperator,
    updatePassword,
    updatePhone,
    updateLegalPerson,
    applicationMain,
    appointmentMain,
    queryMain,
    myExpress,
    IntegratedMachine,
    AddAuthorizationInfo,
    MyAuthorized,
    userCenterInfo,
    applyBuilding,
    StateBox,
  },
  data() {
    return {
      // activeName: this.$hasPermission('IEBDC:SY:WDSQ') ? 'apply' : 'query',
      activeName: 'info',
      user: localDataUser.get(), //当前用户数据
      userType: '',
      tabPosition: 'left', //tab栏方向
      updateInfoTip: '',
      isShow: false, //
      userGpy: '',
      updateType: '',
      isApplyClick: true,
      stateBoxData: {}, //状态箱数据
      dialogVisible: false, //选择设备弹窗
    };
  },
  computed: {
    userName() {
      return this.user.agentUser
        ? this.$$plusXing(this.$decryptByDES(this.user.agentUser.name))
        : this.$plusXing(this.$decryptByDES(this.user.name));
    },
  },
  mounted() {
    //获取当前用户数据
    this.user.realNameStatus = this.user.realNameStatus === 1 ? '已实名认证' : '未实名认证';
    this.userType = this.user.userCategory;
    if (this.$route.params.page) {
      this.activeName = this.$route.params.page;
    }
    if (this.$route.query.activeName) {
      this.activeName = this.$route.query.activeName;
    }
  },
  watch: {
    activeName: function (val) {
      this.backTabs();
      switch (val) {
        case 'draft':
          this.stateBoxData = {
            type: 'draft',
            title: '草稿箱',
            dictype: '办理状态',
            data: ['PRVERIFING', 'PRVERIFY_PASSED', 'PRVERIFY_NOTPASS', 'NETAPPLYING', 'VERIFICATION'],
          };
          break;
        case 'blzBox':
          this.stateBoxData = {
            type: 'blzBox',
            title: '办理中',
            dictype: '办理状态',
            data: [
              'NETCHECKING',
              'NETPASSED',
              'REGISTERING',
              'ACCEPTANCE',
              'CHECKING',
              'SAMPLING',
              'APPROVAL',
              'REGISTERING',
              'CERTIFICATING',
              'PAYING',
              'AWARD',
              'PAYINTEGRATION',
            ],
          };
          break;
        case 'finishBox':
          this.stateBoxData = {
            type: 'finishBox',
            title: '办结箱',
            dictype: '办理状态',
            data: ['GD', 'COMPLETE'],
          };
          break;
        case 'rollback':
          this.stateBoxData = {
            type: 'rollback',
            title: '回退箱',
            dictype: '办理状态',
            data: ['REJECT', 'NETNOPASS'],
          };
          break;
      }
    },
  },
  methods: {
    applyClick(val) {
      if (val == 'apply') {
        this.isApplyClick = !this.isApplyClick;
      }
    },
    userCenterInfoChange(val) {
      switch (val) {
        case 'updatePassword':
          this.updatePassword();
          break;
        case 'updatePhone':
          this.updatePhone();
          break;
        case 'updateLegalPerson':
          this.updateLegalPerson();
          break;
        case 'agreeChange':
          this.findUserD();
          break;
        case 'choiceMachine':
          this.choiceMachine();
          break;
      }
    },
    // 修改密码
    updatePassword() {
      this.updateInfoTip = '密码修改';
      this.updateType = 'pwd';
    },
    // 修改电话
    updatePhone() {
      this.updateInfoTip = '联系电话修改';
      this.updateType = 'phone';
    },
    // 修改法人信息
    updateLegalPerson() {
      this.updateType = 'legalPerson';
      this.updateInfoTip = '法人信息修改';
    },
    // 增加授权信息
    addEmpowerInfo() {
      this.updateType = 'empowerInfo';
      this.updateInfoTip = '增加授权信息';
    },
    // 修改成功
    updateSuccess() {
      this.updateType = '';
      // 重新获取缓存数据
      this.findUserD();
    },
    //获取当前用户详情
    findUserD() {
      findUserCenter({
        userName: this.user.loginName,
      }).then((res) => {
        if (res.success && res.data) {
          this.userGpy = res.data.institutionalUser;
          //判断是否验证
          let userInfo = res.data.userinfo ? res.data.userinfo : res.data.institutionalUser;
          // 用户类型
          userInfo.userType = res.data.institutionalUser ? 'jg' : 'gr';
          // 同意联系电话字段
          userInfo.tel = userInfo.tel ? userInfo.tel : userInfo.lxdh;
          // 用户是否实名注册
          userInfo.authName = res.data.authName;
          //代理人角色
          if (res.data.agentUser) {
            userInfo.agentUser = res.data.agentUser;
            userInfo.id = res.data.agentUser.id;
            userInfo.realNameStatus = res.data.agentUser.realNameStatus;
          }
          userInfo = Object.assign(localDataUser.get(), userInfo);
          localDataUser.set({
            ...userInfo,
          });
          this.user = Object.assign({}, userInfo);
          this.user.realNameStatus = this.user.realNameStatus === 1 ? '已实名认证' : '未实名认证';
        }
      });
    },
    // 选择设备弹框
    openIntegratedDialog() {
      this.$refs.integrated.open();
    },
    // 选择一体机
    choiceMachine() {
      this.openIntegratedDialog();
    },
    backTabs() {
      this.updateType = '';
      this.updateInfoTip = '';
    },
    //扫脸实名认证
    smrz() {
      if (this.user) {
        //是否活体实名
        if (Number(this.user.realNameStatus) !== 1) {
          //获取二维码
          if (this.user.agentUser) {
            this.$slsm();
          } else if (this.user.userCategory === 'NORMAL') {
            this.$slsm();
          } else {
            this.$qyrz();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$leftWidth: 200px;

.container {
  position: relative;
  height: auto;
  background-color: #fff;
  margin-top: 10px;
  display: flex;
  .left {
    width: $leftWidth;
    height: 100%;
    // display: flex;
    // justify-content: center; /* 水平居中 */
    // align-items: center; /* 垂直居中 */
    border: 1px solid #eee;
    // box-shadow: 1px #eee;
    .img-box {
      width: $leftWidth;
      height: 80px;
      margin-top: 10px;
    }
    .realNameStyle {
      color: #00b722;
      margin-bottom: 20px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 79px;
        height: 20px;
        border: 1px solid #00b722;
      }
    }
    .realNameStyleSHZ {
      color: orange;
      margin-bottom: 20px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 79px;
        height: 20px;
        border: 1px solid orange;
      }
    }
    .realNameStyleNo {
      color: red;
      margin-bottom: 20px;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 79px;
        height: 20px;
        border: 1px solid red;
      }
    }
    .user-name {
      width: $leftWidth;
      height: 30px;
      margin-top: 20px;
    }
    .text-ellipsis-left {
      width: 160px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    /deep/ .el-tabs--left {
      height: 500px !important;
      // height: 96% !important;
    }

    /deep/ .el-tabs__nav-scroll {
      width: $leftWidth;
    }
    /deep/.el-tabs--left .el-tabs__nav-wrap.is-left::after {
      width: 0px;
    }

    /deep/ .el-tabs__active-bar {
      display: none;
    }

    /deep/ .el-tabs__item.is-left {
      text-align: center;
    }

    /deep/ .el-tabs__item.is-active {
      background-color: rgba(76, 180, 251, 0.1);
      color: #666;
      cursor: pointer;
    }

    .user-detail {
      img {
        width: 25px;
        height: 25px;
        position: absolute;
        left: 20px;
        top: 7px;
      }
      // .span-box {
      //   // display: flex;
      //   // align-items: center;
      //   // justify-content: center;
      //   // vertical-align: middle;
      // }
      .span-name {
        position: absolute;
        left: 55px;
        top: 0px;
        font-weight: bold;
        font-size: 18px;
      }
      .span-name-child {
        position: absolute;
        left: 55px;
        top: 0px;
        font-weight: bold;
      }
      .span-name-icon {
        position: absolute;
        right: 20px;
        top: 12px;
      }
    }
  }

  //   .query {
  //     // position: absolute;
  //     // top: 100px;
  //     // right: 200px;
  //     display: flex;
  //     position: absolute;
  //     top: 0;
  //     left: $leftWidth + 20px;
  //     padding: 10px 0;
  //     span {
  //       display: inline-block;
  //       padding: 0 20px;
  //       height: 42px;
  //       font-size: 16px;
  //       color: #0168b7;
  //       border-bottom: 2px solid #0168b7;
  //       width: $leftWidth;
  //       text-align: center;
  //     }
  //     div {
  //       margin-left: 200px;
  //     }

  //     .form-control {
  //       width: 220px;
  //       margin-right: 10px;
  //     }
  //   }

  .right {
    position: relative;
    width: calc(100% - 230px);
    height: auto;
    padding: 10px;
    overflow: auto;
    .user-info-update {
      margin-top: 20px;
      div {
        &:first-child {
          height: 30px;
          border-bottom: 1px solid #e7e7e7;
          span {
            display: inline-block;
            height: 100%;
            width: 100px;
            text-align: center;
            color: #409eff;
            border-bottom: 1px solid #409eff;
          }
        }
        &:last-child {
          padding: 20px;
        }
      }
    }
  }
  //   .right:before,
  //   .right:after {
  //     display: table;
  //     content: ''; /*不用有内容也可以*/
  //   }

  //   .right:after {
  //     clear: both;
  //   }

  //   .right {
  //     *zoom: 1;
  //   }
  /deep/ .el-dialog {
    .el-dialog__header {
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      height: 44px !important;
      background-color: #f3f3f3;
      .el-dialog__title {
        color: #333333;
        font-size: 16px;
        font-weight: 400;
      }
      button {
        top: 12px;
        .el-icon-close {
          color: #ffffff;
          font-size: 22px;
        }
      }
    }
    .el-dialog__body {
      padding: 35px 50px;
      max-height: 360px;
      overflow-y: auto;
      &::-webkit-scrollbar-track-piece {
        background: #d3dce6;
      }

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #99a9bf;
      }
    }
    .el-dialog__footer {
      padding-bottom: 38px;
      text-align: center;
    }
    /deep/.public-el-button-default,
    .public-el-button {
      border-radius: 5px;
    }
    /deep/ .el-date-editor.el-input,
    .el-select,
    .el-cascader {
      width: 100%;
    }
    /deep/ .el-form-item__label {
      padding: 0 12px;
    }
    .line {
      text-align: center;
    }
  }
}
</style>
