import request from '../utils/request';
import { REST_SERVICE } from '../config';

// 根据多个id获取多个文件详情
export const getFileListByIdList = (params) =>
  request({
    url: REST_SERVICE.files.getFileListByIdList,
    method: 'get',
    params,
  });
// 根据id删除文件
export const delFileById = (params) =>
  request({
    url: REST_SERVICE.files.delFileById,
    method: 'get',
    params,
  });

// 查询 文章的类型
export const getfileType = (params) =>
  request({
    url: REST_SERVICE.policy.getfileType,
    method: 'get',
    params,
  });

// 查询 文章列表 getPolicyData
export const getPolicyData = (params) =>
  request({
    url: REST_SERVICE.policy.getPolicyData,
    method: 'get',
    params,
  });

//下载 下载文章
export const fileDetail = (params) =>
  request({
    url: REST_SERVICE.files.fileDetail,
    method: 'get',
    params,
  });
