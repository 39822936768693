<template>
  <el-form label-width="120px" :model="formData" ref="form">
    <el-form-item label="登录名">
      <el-input v-model="user.loginName" disabled></el-input>
    </el-form-item>
    <el-form-item label="联系电话">
      <el-input v-model="user.tel" disabled></el-input>
    </el-form-item>
    <el-form-item label="旧密码" prop="oriPassword" :rules="[rules.password, rules.required]">
      <el-input :type="eyeStatus[0] ? 'text' : 'password'" v-model="formData.oriPassword">
        <i
          slot="suffix"
          @click="eyeChange(0)"
          :class="eyeStatus[0] ? 'iconicon-test' : 'iconguanbi-yanjing'"
          class="iconfont"
        ></i>
      </el-input>
    </el-form-item>
    <el-form-item
      label="新密码"
      prop="newPassword"
      :rules="[rules.newPassword, rules.required, rules.oldPassword(formData.oriPassword)]"
    >
      <el-input :type="eyeStatus[1] ? 'text' : 'password'" v-model="formData.newPassword">
        <i
          slot="suffix"
          @click="eyeChange(1)"
          :class="eyeStatus[1] ? 'iconicon-test' : 'iconguanbi-yanjing'"
          class="iconfont"
        ></i>
      </el-input>
    </el-form-item>
    <el-form-item
      label="确认密码"
      prop="newPasswords"
      :rules="[rules.password, rules.required, rules.samePassword(formData.newPassword)]"
    >
      <el-input :type="eyeStatus[2] ? 'text' : 'password'" v-model="formData.newPasswords">
        <i
          slot="suffix"
          @click="eyeChange(2)"
          :class="eyeStatus[2] ? 'iconicon-test' : 'iconguanbi-yanjing'"
          class="iconfont"
        ></i>
      </el-input>
    </el-form-item>
    <el-form-item class="text-center">
      <el-button type="primary" @click="submit">确认修改</el-button>
      <el-button type="primary" @click="close">关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { crypto } from 'iebdc/utils/crypto.js';
import { updataPwd } from 'iebdc/api/user-center/update-userinfo';
import { localDataUser } from '@iebdc/utils/local-data';
import rules from '@/utils/validate.js';
export default {
  name: 'update-password',
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      eyeStatus: [false, false, false],
      formData: {
        oriPassword: '',
        newPassword: '',
        newPasswords: '',
      },
      rules: rules,
      user: {
        loginName: this.userInfo.agentUser ? this.userInfo.agentUser.loginName : this.userInfo.loginName,
        tel: this.userInfo.agentUser ? this.userInfo.agentUser.lxdh : this.userInfo.tel,
      },
    };
  },
  methods: {
    eyeChange(eq) {
      let oldArr = this.eyeStatus;
      oldArr.forEach((el, index) => {
        if (eq === index) {
          oldArr[index] = !oldArr[eq];
          this.$set(this.eyeStatus, index, oldArr[index]);
        }
      });
    },
    // 验证表单
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.updatePassword();
        }
      });
    },
    // 修改密码
    updatePassword() {
      const params = {
        areaCode: window.CONFIG.code,
        oriPassword: this.formData.oriPassword,
        newPassword: this.formData.newPassword,
        loginName: this.userInfo.loginName,
      };
      if (this.$hasPermission('IEBDC:DL:TYRZ')) {
        params.ticket = localDataUser.get() ? localDataUser.get().UNIFIED_AUTHORIZATION : '';
      }
      updataPwd(params).then((res) => {
        if (res && res.success) {
          this.$alert('密码修改成功，请重新登陆！', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: () => {
              localDataUser.clear();
              if (this.$hasPermission('IEBDC:DL:TYRZ')) {
                window.location.href = `${window.CONFIG.UNIFIED_LOGOUT}?backUrl=${window.CONFIG.UNIFIED_PAGE}`;
              } else {
                this.$message.success('退出成功！');
                setTimeout(() => {
                  location.reload(); //解决从首页登录后再从首页登出，页面不刷新问题
                  this.$router.push('/home-login-out');
                }, 500);
              }
            },
          });
        }
      });
    },
    // 关闭
    close() {
      this.$emit('success');
    },
  },
};
</script>

<style lang="scss" scoped>
.iconfont {
  cursor: pointer;
}
</style>
