<template>
  <div class="user-info">
    <div class="user-info-form">
      <el-form :label-position="labelPosition" label-width="210px" :model="user">
        <el-form-item label="账号名称：">
          <span>{{ userName }}</span>
        </el-form-item>

        <el-form-item label="手机号码：">
          <span>{{ userPhone }}&nbsp;&nbsp;<i @click="updatePhone" class="icon el-icon-edit link pointer"></i></span>
        </el-form-item>

        <!-- <el-form-item label="实名认证：">
          <span v-show="user.realNameStatus === '已实名认证'">{{ user.realNameStatus }}</span>
          <span v-show="!user.agentUser && user.status === 'SHZ'">审核中</span>
          <el-link type="warning" v-show="user.realNameStatus !== '已实名认证' && user.status !== 'SHZ'" @click="smrz">{{
            user.realNameStatus
          }}</el-link>
        </el-form-item> -->

        <el-form-item label="高拍仪型号：" v-if="user.userType === 'jg'">
          <el-radio v-model="gpybh" label="lt" @change="agreeChange">良田</el-radio>
          <el-radio v-model="gpybh" label="gz" @change="agreeChange">光阵</el-radio>
          <el-radio v-model="gpybh" label="jy" @change="agreeChange">捷宇</el-radio>
          <el-radio v-model="gpybh" label="ty" @change="agreeChange">通用</el-radio>
        </el-form-item>

        <el-form-item label="仪器编号：" v-if="$hasPermission('IEBDC:SSYTJ')">
          <span>{{ chooseDeviceInfo.deviceBh ? chooseDeviceInfo.deviceBh : chooseDeviceInfo.deviceIp }}</span>
          <el-button type="primary" size="mini" @click="choiceMachine">选择一体机</el-button>
        </el-form-item>

        <div v-if="user.userType === 'jg' && !user.agentUser">
          <el-form-item label="法人名称：">
            <span>{{ user.frmc }}</span>
          </el-form-item>
          <el-form-item label="法人手机号(当前用户手机号)：">
            <span>{{ user.frdh }}</span>
          </el-form-item>
          <el-form-item label="法人证件号：">
            <span>{{ user.frzjh }}</span>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="user-info-button">
      <el-button class="link pointer" @click="updatePassword">修改密码</el-button>
      <el-button @click="updateLegalPerson" class="link" v-if="user.userType === 'jg' && !user.agentUser">
        <i v-if="user.userType === 'jg' && !user.agentUser" class="icon el-icon-edit link pointer"> 法人信息修改 </i>
      </el-button>
    </div>
  </div>
</template>

<script>
import { localDataUser } from '@iebdc/utils/local-data';
import { getDeviceList, saveCompany } from '@iebdc/api/user-center/device.js';

export default {
  name: 'user-center-info',
  props: ['userGpy'],
  data() {
    return {
      user: localDataUser.get(), //当前用户数据
      gpybh: '', //高拍仪型号
      labelPosition: 'right',
      chooseDeviceInfo: {},
      userName: '',
      userPhone: ''
    };
  },
  computed: {
    // userName() {
    //   return this.user.agentUser
    //     ? this.$plusXing(this.$decryptByDES(this.user.agentUser.name))
    //     : this.$plusXing(this.user.name);
    // },
    // userPhone() {
    //   return this.user.agentUser
    //     ? this.$decryptByDES(this.user.agentUser.lxdh).replace(/^(\w{3})\w{4}(.*)$/, '$1****$2')
    //     : this.user.tel;
    // },
  },
  mounted() {
    //获取当前用户数据
    this.user.realNameStatus = this.user.realNameStatus === 1 ? '已实名认证' : '未实名认证';
    if (this.user.gpybh) {
      this.gpybh = this.user.gpybh;
    } else {
      this.gpybh = '';
    }
    if (!JSON.parse(localStorage.getItem('chooseDeviceInfo'))) {
      //缓存中没有值的时候执行接口获取之前选择的设备
      this.getDevice();
    } else {
      //有值直接获取存储中的值
      this.chooseDeviceInfo = JSON.parse(localStorage.getItem('chooseDeviceInfo'));
    }
    // this.user.frmc = this.$decryptByDES(this.user.frmc);
    // this.user.frdh = this.$decryptByDES(this.user.frdh);
    // this.user.frzjh = this.$decryptByDES(this.user.frzjh);
    this.userName = this.user.agentUser? this.$plusXing(this.$decryptByDES(this.user.agentUser.name)): this.$plusXing(this.user.name);
    this.userPhone = this.user.agentUser? this.$decryptByDES(this.user.agentUser.lxdh): this.user.tel;
    console.log('this.user----userInfo', this.user);
  },
  methods: {
    //修改电话
    updatePhone() {
      this.$emit('userCenterInfoChange', 'updatePhone');
    },
    //修改法人
    updateLegalPerson() {
      this.$emit('userCenterInfoChange', 'updateLegalPerson');
    },
    //修改密码
    updatePassword() {
      this.$emit('userCenterInfoChange', 'updatePassword');
    },
    //选择一体机
    choiceMachine() {
      this.$emit('userCenterInfoChange', 'choiceMachine');
    },
    agreeChange(val) {
      this.gpybh = val;
      // this.$emit('userCenterInfoChange', 'agreeChange');
      setTimeout(() => {
        let InstitutionalUserQueryDto = {
          id: this.user.id,
          gpybh: this.gpybh,
        };
        this.save(InstitutionalUserQueryDto);
      }, 1000);
    },
    save(params) {
      saveCompany(params).then((res) => {
        if (res.success) {
          localDataUser.update({ gpybh: params.gpybh });
          // this.$emit('userCenterInfoChange', 'agreeChange');
        }
      });
    },
    getDevice() {
      getDeviceList({ deviceUser: this.user.id }).then((res) => {
        if (res.success && res.data) {
          res.data.forEach((item, index) => {
            if (item.defaultStatus === 0) {
              this.$set(this.chooseDeviceInfo, 'deviceBh', res.data[index].deviceBh);
              this.$set(this.chooseDeviceInfo, 'deviceIp', res.data[index].deviceIp);
              this.$set(this.chooseDeviceInfo, 'deviceId', res.data[index].id);
              localStorage.setItem('chooseDeviceInfo', JSON.stringify(this.chooseDeviceInfo));
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info {
  // display: flex;
  //   justify-content: center; /* 水平居中 */
  //   align-items: center; /* 垂直居中 */
  //   font-size: 25px;
  margin-top: 40px;
  .user-info-button {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
  }
  .user-info-form {
    width: 100%;
    margin-top: 10px;
    margin-left: -8%;
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    /deep/.el-form-item__label {
      font-weight: bold;
    }
  }
}
</style>
