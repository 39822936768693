import request from 'iebdc/utils/request';
import { REST_SERVICE } from 'iebdc/config';

// 添加代理人
export const addAgent = (params) =>
  request({
    url: REST_SERVICE.dlr.addAgent,
    method: 'post',
    data: params
  });
// 查找代理人
export const getAgentsByUserId = (params) =>
  request({
    url: REST_SERVICE.dlr.getAgentsByUserId,
    method: 'get',
    params
  });
// 添加代理人
export const updateAgent = (params) =>
  request({
    url: REST_SERVICE.dlr.updateAgent,
    method: 'post',
    data: params
  });

// 删除代理人
export const deleteAgent = (params) =>
  request({
    url: REST_SERVICE.dlr.deleteAgentById,
    method: 'post',
    data: params
  });
