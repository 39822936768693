<template>
  <div class="container">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基本信息" name="0">
        <el-tabs v-model="activeNameTwo" type="card">
          <el-tab-pane v-for="(item, index) in config" :key="index" :label="item.name" :name="index + ''">
            <sqb-form
              ref="sqbForm"
              v-if="config[index]"
              :formData="formModal.children[index]"
              :configs="config[index].config"
              :disabled="disabled"
            ></sqb-form>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>
      <el-tab-pane label="材料列表" name="1">
        <upload-file :checkdisabled="false"></upload-file>
      </el-tab-pane>
      <el-tab-pane label="预审信息" name="2">
        <examination-tab :ysData="formModal"></examination-tab>
      </el-tab-pane>
    </el-tabs>
    <el-button type="primary" class="btn" @click="back">返回</el-button>
  </div>
</template>

<script>
import uploadFile from 'iebdc/views/apply/flow/uploadFile';
import ExaminationTab from './el-tab/examination-tab';
export default {
  components: {
    uploadFile,
    ExaminationTab,
  },
  props: {
    formModal: Object,
    config: Array,
    disabled: Boolean,
  },
  // mounted() {
  //   if (this.formModal) {
  //     this.ysData = this.formModal;
  //   }
  // },
  data() {
    return {
      check: true,
      activeName: '0',
      activeNameTwo: '0',
      // ysData: {}, //预审信息
      defaultId: '',
    };
  },
  methods: {
    back() {
      this.$emit('back');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.table-outer {
  width: 100%;
  height: 110px !important;
}
.lit-title {
  margin-top: 10px;
  cursor: pointer;
  display: block;
  color: #177dc7;
  font-size: 14px;
}
.ncol-title {
  margin: 15px 0;
  display: block;
  font-size: 14px;
}
.lit-container {
  width: 260px;
  height: 100px;
  border: 2px dashed #177dc7;
  margin-left: 30px;
  padding: 10px;
}
.container {
  position: relative;
}
.btn {
  position: absolute;
  top: 0;
  right: 0;
}
.express-address {
  .address-content {
    margin-top: 10px;
    border-top: 1px dashed rgb(227, 227, 227);
    padding-top: 10px;
    span {
      margin-right: 5px;
    }
    .blue {
      color: #177dc7;
      cursor: pointer;
    }
    .red {
      color: #f53f5e;
      cursor: pointer;
    }
  }
}
</style>
