<template>
  <div class="container">
    <e-dialog :config="openAddConfig" :btns="openAddBtns" ref="dialog">
      <template slot="content" ref="formcontent">
        <!-- 材料配置 -->
        <!-- <div class="guid">
        <span class="guid-title">缴费详细</span>
         <span class="guid-button">
          <el-button type="primary" size="small" icon="el-icon-plus" @click="addRow">新增</el-button>
        </span> 
      </div>-->
        <!-- <e-table
        ref="table"
        style="height: 200px"
        :options="options"
        :columns="columns"
        :operates="operates"
        row-key="id"
      ></e-table> -->
        <el-form ref="form" size="small" :model="formData" label-width="110px" label-position="right">
          <el-row type="flex" justify="center">
            <el-col :span="24">
              <el-form-item label="缴费单位(个人):" prop="description" label-width="120px">
                <el-input v-model="formData.userName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row type="flex" justify="center">
            <el-col :span="12">
              <el-form-item label="执收单位名称" prop="cnqx">
                <el-input v-model="formData.zsdwmc" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="缴费总金额" prop="fdqx">
                <el-input v-model="formData.money" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="center">
            <el-col :span="24">
              <el-form-item label="缴费项目:" prop="" label-width="120px">
                <el-select
                  value-key="id"
                  v-model="fromFeeDatas"
                  multiple
                  :multiple-limit="5"
                  @change="changeFee"
                  size="medium"
                  style="width: 100%"
                >
                  <el-option v-for="item in paySelectData" :key="item.id" :value="item" :label="item.projectName"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row v-for="(feeData, index) in formData.feeDatas" :key="index" type="flex" justify="space-between">
          <el-col :span="8">
            <el-form-item class="sfxm" label="收费项目">
              <el-input v-model="formData.feeDatas[index].yjxm" placeholder=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item prop="yjje" label="缴费总金额" :rules="[rules.money]">
              <el-input v-model="formData.feeDatas[index].money"></el-input>
            </el-form-item>
          </el-col>

          <div class="width160">
            <el-button type="primary" v-if="formData.feeDatas.length < 5" @click="addBdcDyh()">添加</el-button>
            <el-button type="primary" v-if="formData.feeDatas.length > 1" @click="delBdcDyh(index)">删除</el-button>
          </div>
        </el-row> -->
        </el-form>
      </template>
    </e-dialog>
  </div>
</template>

<script>
import rules from '@/utils/validate.js';
import payOnline from '@iebdc/api/pay-online.js';
import payVerify from '@manager/api/verify/payVerify';
import { getProcessInfo } from 'iebdc/api/my-application.js';
import parseJSON from 'date-fns/esm/parseJSON/index';
// import appointmentService from '@manager/api/processDefinition/appointment-service';
export default {
  name: 'detail',
  components: {},
  data() {
    return {
      fiveNumber: 5,
      flag: false,
      // 表格数据
      tableList: [],
      // 打开新增弹框配置
      openAddConfig: {
        title: '缴费信息',
        width: '800px',
        // height:""
        visible: false,
        hasConfirm: false,
        hasCancel: false,
        hasBtn: true,
        appendBody: true,
      },
      paymentData: [],
      fromFeeDatas: [],
      openAddBtns: [
        {
          label: '关闭',
          class: 'public-el-button-default',
          method: () => {
            this.openAddConfig.visible = false;
          },
          show: true,
        },
        {
          label: '保存',
          type: 'primary',
          class: 'public-el-button',
          method: this.saveForm,
          show: true,
        },
      ],
      // 校验规则
      rules: rules,
      // 维护表单数据
      formData: {
        jfdw: '',
        zsdwmc: '',
        jfje: '',
        feeDatas: [],
      },
      // 需要提交给后台的数据
      submitFormData: {
        id: '',
      },
      paySelectData: [],
      orderNo: '',
      options: {
        // 每页数据数
        // pageSize: 10,
        // 当前页码
        currentPage: 0,
        // 是否有序号
        hasIndex: true,
        loading: true,
        //没有斑马线
        stripe: false,
        hasPagination: false,
      },
      // 表格配置列
      columns: [
        {
          prop: 'sfxm',
          label: '收费项目',
        },
        {
          prop: 'num',
          label: '数量',
        },
        {
          prop: 'sfbz',
          label: '收费标准',
        },
        {
          prop: 'yjje',
          label: '应缴金额',
        },
      ],
      operates: {
        //表格操作栏
        width: 80,
        list: [
          {
            id: '1',
            label: '编辑',
            show: true,
            spanType: 'render',
            method: (key, row) => {
              row.editable = true;
            },
            showCallback: () => {
              return true;
            },
            render: (h, scope) => {
              const linkAttrs = {
                type: 'primary',
                underline: false,
              };
              return scope.row.editable ? (
                <el-link
                  {...{ attrs: linkAttrs }}
                  vOn:click_stop_prevent={() => {
                    this.handleSave(scope);
                  }}
                >
                  保存
                </el-link>
              ) : (
                <el-link
                  {...{ attrs: linkAttrs }}
                  vOn:click_stop_prevent={() => {
                    this.handleEdit(scope);
                  }}
                >
                  编辑
                </el-link>
              );
            },
          },
          {
            id: '3',
            label: '删除',
            show: true,
            method: (index) => {
              this.$confirm('是否删除当前行？', '确认信息', {
                distinguishCancelAndClose: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(() => {
                console.log('index', index);
                this.tableList.splice(index, 1);
              });
            },
            showCallback: () => {
              return true;
            },
          },
        ],
      },
    };
  },
  methods: {
    handleSave(scope) {
      this.$set(scope.row, 'editable', false);
      //   this.saveForm();
    },
    addBdcDyh() {
      this.formData.feeDatas.push({ yjxm: '', money: '' });
    },
    delBdcDyh(index) {
      this.formData.feeDatas.splice(index, 1);
    },
    handleEdit(scope) {
      this.$set(scope.row, 'editable', true);
    },
    getFormData() {
      getProcessInfo({
        wwywh: this.orderNo, //,
      }).then((res) => {
        if (res.data.djjg != null) {
          var paymentAreaDto = {
            areaCode: res.data.djjg,
          };
          this.getPayment(paymentAreaDto);
        }
      });
    },
    changeFee(item) {
      this.formData.money = 0;
      this.formData.feeDatas = [];
      item.forEach((element) => {
        this.formData.money += element.price;
        console.log(element);
        this.formData.feeDatas.push({
          amount: element.amount,
          busCode: element.busCode,
          price: element.price,
        });
      });
      console.log(this.formData);
    },
    getPayment(data) {
      console.log(data);
      this.paySelectData = [];
      payOnline.selectByParams(data).then((res) => {
        if (res.success) {
          if (res.data.length > 0) {
            this.formData.orgName = res.data[0].orgName;
            this.formData.orgCode = res.data[0].orgCode;
            res.data.forEach((element) => {
              this.paySelectData.push({
                projectName: element.projectName,
                busCode: element.projectCode,
                amount: 1,
                price: element.price,
                id: element.id,
              });
            });
          }
        }
      });
    },
    cancelOpened() {
      console.log(this.$refs.formcontent);
      this.$refs.dialog.scrollTop = 0;
      this.$refs.form.scrollTop = 0;

      this.$refs.dialog.$el.scrollTop = 0;
      this.$refs.form.$el.scrollTop = 0;
    },
    // 重置表单
    resetForm(formName) {
      if (this.$refs[formName]) {
        this.$refs[formName].resetFields();
      }
    },
    addRow() {
      this.tableList.push({
        jfdw: '',
        sfxm: '',
        num: '',
        sfbz: '',
        yjje: '',
      });
      this.$refs.table.setTableList(this.tableList || []);
    },
    open(row) {
      // 表单赋值id
      this.orderNo = row.wwywh;
      this.formData.userName = row.qlr;
      this.openAddConfig.visible = true;
      // 重置表单
      this.resetForm('form');
      this.$nextTick(() => {
        this.getFormData();
      });
    },
    // table状态切换
    ztRender(h, row, prop) {
      this.sfzs = row['sfzs'];
      const val = row[prop] === true ? '是' : '否';
      return h('span', {
        domProps: {
          innerHTML: val,
        },
        style: {
          marginRight: '10px',
        },
      });
    },
    // 提交数据
    saveForm() {},
    mounted() {},
  },
};
</script>

<style scoped lang="scss">
.guid {
  display: flex;
  justify-content: space-between;
  .guid-button {
    margin-bottom: 2px;
  }
}
/deep/ .el-dialog .el-dialog__body.el-dialog__body {
  padding: 35px 30px;
  max-height: auto;
  overflow-y: auto;
}
/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 18px;
  width: 100%;
}
</style>
